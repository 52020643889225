<button
  *ngIf="isSearcher"
  matSuffix
  mat-icon-button
  class="searcher-btn"
  aria-label="Open"
  #triggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="searcher"
  (click)="toggleShowInput()"
  (menuOpened)="setFocusSearchInput()"
  (menuClosed)="toggleShowInput('searchInput')"
  [ngStyle]="sizeBtn"
>
  <mat-icon class="d-flex justify-content-center align-items-center" [@rotate]="rotateAnimationState"
    >expand_more</mat-icon
  >
</button>

<mh-core-lib-actions-wrapper
  *ngIf="isCases"
  slug="cases_topbar_case_create"
  tooltipPosition="below"
  >
<button
  class="create-case-btn"
  mat-icon-button
  (click)="createCase()"
  (menuClosed)="toggleShowInput()"
  placement="bottom"
  ngbTooltip="{{ 'cases.titles.form' | translate }}"
>
  <mat-icon>add</mat-icon>
</button>
</mh-core-lib-actions-wrapper>

<button
  *ngIf="isLanguages && !isMobile"
  matSuffix
  mat-icon-button
  class="language-btn"
  aria-label="Open"
  #triggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="languages"
  (click)="activeLanguageBtn = true"
  (menuClosed)="activeLanguageBtn = false"
  [class.active]="activeLanguageBtn"
>
  <span>{{ currentLanguage | uppercase }}</span>
</button>

<button
  *ngIf="isLanguages && isMobile"
  mat-icon-button
  class="language-btn-mobile btn"
  aria-label="Open"
  [disableRipple]="true"
  #triggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="languages"
  (click)="toggleRotateIcon()"
  (menuClosed)="toggleRotateIcon()"
>
  {{ currentLanguage | uppercase }}
  <mat-icon [@rotate]="rotateAnimationState">expand_more</mat-icon>
</button>

<div *ngIf="isNotifications" class="tw-relative">
  <p
    *ngIf="alertsData?.length > 0 && alertsData[0]?.total_unread > 0 || newsData?.length > 0 && newsData[0]?.total_unread > 0"
    class="tw-bg-red-600 tw-text-white tw-rounded-full tw-text-xs tw-absolute -tw-bottom-1 -tw-right-1 tw-px-[5px] tw-z-10"
  >
    {{ (alertsData?.length > 0 ? alertsData[0].total_unread : 0) + (newsData?.length > 0 ? newsData[0].total_unread : 0) }}
  </p>
  <button
    #triggerNoti="cdkOverlayOrigin"
    matSuffix
    mat-icon-button
    aria-label="Open"
    cdkOverlayOrigin
    (click)="clickCNBtn()"
    (menuClosed)="activeNotiBtn = false"
    [class.active]="activeNotiBtn"
  >
    <i class="far fa-concierge-bell tw-text-xl"></i>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerNoti"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPanelClass]="['!tw-right-0', '!tw-left-auto']"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (detach)="isOpen = false; activeNotiBtn = false"
    (backdropClick)="isOpen = false; activeNotiBtn = false"
  >
    <div
      class="tw-w-[29rem] tw-max-h-[95vh] tw-mt-[0.9rem] tw-bg-white tw-pr-1 tw-pt-4 tw-pb-8 tw-shadow-4 tw-overflow-y-auto"
    >
      <mh-notifications
        [alertsData]="alertsData"
        [newsData]="newsData"
        [type]="notificationComponentType"
        [currentUser]="currentUser"
        [currentLanguageId]="currentLanguageId"
        [hasMoreAlerts]="hasMoreDataAlerts"
        [hasMoreNews]="hasMoreDataNews"
        (emitCloseCentral)="handleHistoryBtn($event)"
        (emitShowMore)="handleShowMore($event)"
        (emitUpdate)="getCommunications($event)"
      ></mh-notifications>
    </div>
  </ng-template>
</div>

<button
  *ngIf="isSupport"
  matSuffix
  mat-icon-button
  aria-label="Open"
  #triggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="support"
  (click)="activeSupportbtn = true"
  (menuClosed)="activeSupportbtn = false"
  [class.active]="activeSupportbtn"
>
  <mat-icon>support_agent</mat-icon>
</button>

<button
  *ngIf="isMenu && !isMobile"
  matSuffix
  mat-icon-button
  aria-label="Open"
  #triggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  (click)="activeUserBtn = true"
  (menuClosed)="activeUserBtn = false"
  [class.active]="activeUserBtn"
>
  <mh-user-thumbnail [user]="userProfile" [showBorder]="false" [activeMenu]="activeUserBtn"></mh-user-thumbnail>
</button>

<button *ngIf="isMenu && isMobile" matSuffix mat-icon-button aria-label="Open" (click)="openSideMenu()">
  <mh-user-thumbnail [user]="userProfile" [showBorder]="false" [activeMenu]="activeUserBtn"></mh-user-thumbnail>
</button>

<mat-menu class="finder-input {{ mobileClassSearcher }}" #searcher="matMenu" xPosition="before">
  <div class="finder">
    <mh-hotel-finder
      [showLabel]="false"
      [currentUser]="currentUser"
      [isSearcherMenuOpened]="searcherMenuOpened"
      (isReady)="readyToEmit($event)"
      (click)="$event.stopPropagation()"
      (updated)="customerUpdated($event)"
    >
    </mh-hotel-finder>
  </div>
</mat-menu>

<mat-menu #languages="matMenu" xPosition="before">
  <ng-container *ngFor="let language of availableLanguages">
    <button type="button" class="btn-languages" mat-menu-item (click)="updateLanguage(language)">
      <span class="language-item" [class.active]="isActive(language)">{{
        'languages.iso.' + language | translate
      }}</span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #support="matMenu" xPosition="before">
  <a href="javascript:void(0)" routerLink="/soporte" mat-menu-item class="flex-row-reverse d-flex">
    <span class="menu-item">{{ 'support.menu-header.create' | translate }}</span>
  </a>
  <a [href]="linkSupport" target="_blank" mat-menu-item class="flex-row-reverse d-flex">
    <span class="menu-item">{{ 'support.menu-header.demos' | translate }}</span>
  </a>
</mat-menu>

<mat-menu #menu="matMenu" xPosition="before">
  <mh-new-menu [currentUser]="currentUser" [customer]="customer" (gaEvent)="ga($event)" (closeMenu)="toggleMenu()">
  </mh-new-menu>
</mat-menu>
