import { Component, HostListener, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FollowupOnsite } from 'app/states/followup-onsite/followup-onsite.actions';
import {
  FollowupOnsiteFilterDataGuests,
  FollowupOnsiteState,
  DEFAULT_FILTERS,
} from 'app/states/followup-onsite/followup-onsite.state';
import { Observable, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { animation } from 'app/shared/utils/animation-custom';
import { FilterData, SurveyFilter } from 'app/new-fs/followup-onsite/models/SurveyFilters.model';
import { GuestSelectorService } from 'app/core-lib/services/guest-selector/guest-selector.service';

@Component({
  selector: 'mh-core-lib-guests-filter',
  templateUrl: './guests-filter.component.html',
  styleUrls: ['./guests-filter.component.scss'],
  animations: [animation.rotate, animation.collapsedHeightState],
})
export class CoreLibGuestsFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filters: SurveyFilter;
  isOpen = false;
  isOpenMobile = [
    { target: 'nps', isOpen: false },
    { target: 'country', isOpen: false },
    { target: 'room', isOpen: false },
    { target: 'language', isOpen: false },
    { target: 'origin', isOpen: false },
    { target: 'originName', isOpen: false },
    { target: 'integration', isOpen: false },
    { target: 'cases', isOpen: false },
    { target: 'guests', isOpen: false },
  ];

  npsFilter: any;
  npsOptions = [
    { value: 'promoter', text: 'promoters', selected: false },
    { value: 'passive', text: 'passives', selected: false },
    { value: 'detractor', text: 'detractors', selected: false },
    { value: 'norate', text: 'others', selected: false },
  ];
  deskOptions = [
    { value: 1, text: 'created', selected: false },
    { value: 2, text: 'non_created', selected: false },
  ];
  contactedOptions = [
    { value: 1, text: 'contacted', selected: false },
    { value: 2, text: 'non_contacted', selected: false },
  ];

  languageOptions = [];
  originOptions = [];
  roomOptions = [];
  countryOptions = [];
  channelOptions = [];
  areDefaultFilters = true;
  subscriptions: Subscription;

  @Select(FollowupOnsiteState.filters) filters$: Observable<any>;
  @Select(FollowupOnsiteState.dataFilters) dataFilters$: Observable<FollowupOnsiteFilterDataGuests>;

  @Input()
  isAirline = false;

  @Input()
  isRestaurant = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMenuMobile();
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  constructor(private store: Store, private guestSelectorService: GuestSelectorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && changes.filters.currentValue) {
      this.getFilters();
    }
  }

  ngOnInit(): void {
    this.setMenuMobile();
    this.getFilters();
  }

  setMenuMobile() {
    if (window.innerWidth >= 1280) {
      this.isOpenMobile = this.isOpenMobile.map((filter) => {
        return {
          ...filter,
          isOpen: true,
        };
      });
    }
  }

  checkClickInside(target) {
    if (
      target.id === 'overlayFiltersGuest' ||
      target.id === 'filtersGuest' ||
      target.className?.includes('chip-no-close') ||
      target.className?.includes('generic-option') ||
      target.className?.includes('checkbox-option')
    ) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  getFilters() {
    this.subscriptions = this.filters$.subscribe((filters: any) => {
      this.checkFiltersApplied(filters);

      this.setNPSFilter(filters.nps);
      this.setCasesOptions(filters.cases);
      this.setContactedOptions(filters.contacted);

      if (filters) {
        this.handleFilterData(filters, this.filters);
      }
    });
  }

  checkFiltersApplied(filters) {
    this.areDefaultFilters = isEqual(filters, DEFAULT_FILTERS);
  }

  setNPSFilter(npsFilter) {
    Object.keys(npsFilter).forEach((key) => {
      const index = this.npsOptions.findIndex((option) => option.text === key);
      if (index >= 0) {
        this.npsOptions[index].selected = npsFilter[key];
      }
    });
  }

  setCasesOptions(casesFilter) {
    Object.keys(casesFilter).forEach((key) => {
      const index = this.deskOptions.findIndex((option) => option.text === key);
      if (index >= 0) {
        this.deskOptions[index].selected = casesFilter[key];
      }
    });
  }

  setContactedOptions(contactedFilter) {
    Object.keys(contactedFilter).forEach((key) => {
      const index = this.contactedOptions.findIndex((option) => option.text === key);
      if (index >= 0) {
        this.contactedOptions[index].selected = contactedFilter[key];
      }
    });
  }

  updateGenericFilter(inputs, filterOptions, storeName) {
    const options = inputs.map((input) => {
      const option = filterOptions.find((filterOption) => filterOption.value === input.value);
      option.selected = !input.selected;
      return option;
    });
    const optionsStore = options.map((option) => option.value);
    this.store.dispatch(new FollowupOnsite[storeName](optionsStore));
  }

  updateGenericCheckboxFilter(selectedOption, filterOptions, storeName) {
    const options = filterOptions
      .map((option) => {
        if (selectedOption.value === option.value) {
          option.selected = !selectedOption.selected;
        }
        if (option.selected) {
          return option.value;
        }
      })
      .filter((item) => item || item === 0);

    this.store.dispatch(new FollowupOnsite[storeName](options));
  }

  updateCasesFilter(desk) {
    const previouslySelected = this.deskOptions.find((option) => option.text !== desk.text);

    const desks: any = {
      [desk.text]: !desk.selected,
    };
    if (desks.created) {
      desks.non_created = previouslySelected.selected;
    } else {
      desks.created = previouslySelected.selected;
    }
    this.store.dispatch(new FollowupOnsite.ChangeCasesFilter(desks));
  }

  updateContactedFilter(contacted) {
    const currentFilters = this.store.selectSnapshot(FollowupOnsiteState.filters);
    const contactedFilter = { ...currentFilters.contacted };
    contactedFilter[contacted.text] = !contacted.selected;

    this.store.dispatch(new FollowupOnsite.ChangeContactedFilter(contactedFilter));
  }

  clearFilters() {
    this.store.dispatch(new FollowupOnsite.FilterClear());
    this.guestSelectorService.activateClearFilter = false;
    this.guestSelectorService.updateGuestsOptions({ guests: null, from: 'clear' });
    this.filters.similar_names = null;
  }

  handleFilterData(filters: any, data: SurveyFilter) {
    /* eslint-disable @typescript-eslint/naming-convention */
    const {
      countries_filter_items,
      integration_channel_filter_items,
      language_filter_items,
      origin_filter_items,
      rooms,
    } = data;
    /* eslint-disable @typescript-eslint/naming-convention */
    if (countries_filter_items) {
      this.countryOptions = countries_filter_items.map((country: FilterData) => {
        return {
          value: country.id,
          text: country.id,
          selected: filters.countries?.includes(country.id) ? true : false,
        };
      });
    }
    if (rooms) {
      this.roomOptions = rooms.map((room: string) => {
        return {
          value: room,
          text: room,
          selected: filters.rooms?.includes(room) ? true : false,
        };
      });
    }
    if (language_filter_items) {
      this.languageOptions = language_filter_items.map((language: FilterData) => {
        return {
          value: language.id,
          text: language.id,
          selected: filters.languages?.includes(language.id) ? true : false,
        };
      });
    }
    if (origin_filter_items) {
      this.originOptions = origin_filter_items.map((origin: FilterData) => {
        return {
          value: origin.id,
          text: origin.name,
          selected: filters.origin_name?.includes(origin.id) ? true : false,
        };
      });
    }
    if (integration_channel_filter_items) {
      this.channelOptions = integration_channel_filter_items.map((channel: FilterData) => {
        return {
          value: channel.id,
          text: channel.name,
          selected: filters.channels?.includes(channel.id) ? true : false,
        };
      });
    }
  }

  toggleMobileFilter(target) {
    if (this.isMobileMenu) {
      const index = this.isOpenMobile.findIndex((filter) => filter.target === target);
      this.isOpenMobile[index].isOpen = !this.isOpenMobile[index].isOpen;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get npsFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'nps').isOpen;
  }

  get countryFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'country').isOpen;
  }

  get roomFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'room').isOpen;
  }

  get languageFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'language').isOpen;
  }

  get originFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'origin').isOpen;
  }

  get originNameFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'originName').isOpen;
  }

  get integrationFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'integration').isOpen;
  }

  get casesFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'cases').isOpen;
  }

  get guestsFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'guests').isOpen;
  }

  get isMobileMenu() {
    return window.innerWidth < 1280;
  }

  get areFiltersApplied() {
    return (
      !this.areDefaultFilters ||
      (this.filters?.similar_names && this.filters?.similar_names?.length > 0) ||
      this.guestSelectorService.activateClearFilter
    );
  }
}
