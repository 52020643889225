import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CoreLibNotificationsPopupModalComponent } from 'app/core-lib/dialogs/notifications-popup-modal/notifications-popup-modal.component';
import { Communications } from 'app/shared/notifications/models/communications';

@Component({
  selector: 'mh-core-lib-notifications',
  templateUrl: './core-lib-notifications.component.html',
  styleUrls: ['./core-lib-notifications.component.scss'],
})
export class CoreLibNotificationsComponent {
  @Input() data;
  @Input() type;
  @Input() typeNotification;
  @Output() emitNotificationData = new EventEmitter<{ communication: Communications; status: string }>();
  @Output() emitStatusChanged = new EventEmitter<{ communication: Communications; status: string }>();

  openActionMenu = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.openActionMenu = false;
    }
  }

  constructor(private eRef: ElementRef, private modalService: ModalService) {}

  ngOnChanges({ data }) {
    if (data) this.data = data.currentValue;
  }

  handleClick(type: string) {
    if (type === 'POPUP') {
      this.openPopupModal();
      return;
    }

    const dataToSend = {
      communication: this.data,
      status: 'READ',
    };
    window.open(this.data.translates[0].link, '_blank');
    this.emitStatusChanged.emit(dataToSend);
  }

  openPopupModal() {
    const classes = ['lg', 'overlay-panel', 'fit-height-content', '!tw-p-0'];
    const config = {
      data: { noCloseOnBackdropClick: false, news: [this.data], timer: false, simpleClose: true },
    };
    this.modalService.open(CoreLibNotificationsPopupModalComponent, config, classes, ['overlay-backdrop-notification']);
  }

  handleOmitted(data) {
    const dataToSend = {
      communication: data,
      status: 'OMITTED',
    };
    this.emitStatusChanged.emit(dataToSend);
  }

  checkClickInside(target) {
    console.log(target.id);
    if (target.id === 'overlayNotificationItem') {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }
}
