<section class="tw-flex tw-flex-col tw-relative tw-h-full">
    <i class="far fa-times tw-cursor-pointer tw-absolute tw-right-4 tw-top-3" (click)="close()"></i>

    <section class="tw-w-full tw-flex tw-justify-center tw-py-2 tw-bg-background-modal-title">
        <h1 class="tw-font-medium tw-text-main-text tw-text-lg">
            {{type.translate[0].title}}
        </h1>
    </section>

    <section class="tw-px-5 tw-py-3">
        <div class="tw-text-lg" [innerHtml]="('notifications.greeting' | translate:{user:user})"></div>

        <div class="tw-my-5" [innerHtml]="type.translate[0].emphasis+' '+(distance>0? '<b>'+distance+' Días.</b>' : '') "></div>
        <div class="" [innerHtml]="type.translate[0].remember"></div>
    </section>

    <section class="tw-bg-zinc-100 tw-grid tw-grid-cols-2 tw-gap-5 tw-px-5 tw-py-3 tw-items-center tw-grow" [hidden]="!type.show_account_number">
        <div>
            <label>{{('notifications.account_title_national' | translate)}}</label>
            <div class="account" [innerHtml]="('notifications.account' | translate)"></div>
        </div>

        <div>
            <label>{{('notifications.account_title_international' | translate)}}</label>
            <div class="account" [innerHtml]="('notifications.account_international' | translate)"></div>
        </div>
    </section>

    <div class="tw-bg-zinc-200 tw-py-3 tw-px-5">
        <div [innerHtml]="('notifications.atte' | translate)"></div>
        <img class="logo" src="assets/images/myhotel_logo.png" alt="logo">
    </div>

    <div 
        class="tw-bg-zinc-300 tw-flex tw-justify-center tw-py-3 tw-text-xs tw-px-5" 
        *ngIf="type.translate[0].note" 
        [innerHtml]="type.translate[0].note"
    >
    </div>
</section>
