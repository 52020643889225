<article class="tw-flex tw-space-x-4">
  <button
      class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0 tw-transition-all"
      [class.!tw-bg-main]="isOpen"
      [class.!tw-text-white]="isOpen"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin"
      >
      <i class="fas fa-filter tw-self-center"></i>
      <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center tw-text-main-text tw-transition-all" [class.!tw-text-white]="isOpen">
        {{'core-lib.selectors.desk.filter_by' | translate}}
      </span>
  </button>

  <button class="tw-rounded-full tw-bg-white tw-text-main tw-shadow-1 tw-w-14 tw-h-14 hover:tw-text-white hover:tw-bg-main tw-transition-all focus:tw-outline-0" (click)="clearFilters()" *ngIf="areFiltersApplied">
    <div class="tw-relative">
      <i class="fas fa-filter tw-self-center"></i>
      <i class="fas fa-times-circle tw-absolute tw-text-red-500 tw-text-small tw-right-3.5"></i>
    </div>
  </button>
</article>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPanelClass]="['!tw-inset-x-0', 'tw-mx-auto', 'tw-absolute', 'overlay-centered']"
>
<div class="tw-bg-white tw-shadow-1 tw-rounded tw-flex tw-flex-col tw-mt-3" id="overlayFiltersOnlineReviews">

  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-pb-1 tw-pt-3 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('ota')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">OTA</span>
          <i [@rotate]="otaFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="otaFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="otas"
              [displayOptionsFn]="displayOTAOptions"
              (optionClicked)="setReviewFilterState($event)">
          </mh-core-lib-checkbox-input>

        </div>
      </div>

      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('answer')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'commons.answer' | translate}}</span>
          <i [@rotate]="answerFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="answerFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="answerOptions"
              [displayOptionsFn]="displayAnswerOptions"
              (optionClicked)="setReviewFilterState($event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('nps')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">NPS</span>
          <i [@rotate]="npsFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="npsFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="npsOptions"
              [displayOptionsFn]="displayNpsOptions"
              (optionClicked)="setReviewFilterState($event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
  </div>
  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('qualification')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'commons.qualify' | translate}}</span>
          <i [@rotate]="qualificationFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="qualificationFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="qualificationOptions"
              [displayOptionsFn]="displayQualificationOptions"
              [displayHtml]="true"
              (optionClicked)="setReviewFilterState($event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('reason')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'commons.type_traveler.title' | translate}}</span>
          <i [@rotate]="reasonFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="reasonFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-tag-input
              [options]="reasonOptions"
              [displayChipsFn]="displayChipsFn"
              [displayChipsListFn]="displayChipsFn"
              [allSelected]="false"
              [checkedAllOption]="false"
              [showAutocompleteInput]="true"
              [inputTagFilter]="inputTagFilter"
              [displayCheckInput]="false"
              [boxOpen]="false"
              [placeholder]="('commons.work' | translate | titlecase)+', '+('commons.couples' | translate | titlecase)+'...'"
              (optionClicked)="setReviewFilterState($event)"
              ></mh-core-lib-tag-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('language')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'languages.iso.language' | translate}}</span>
          <i [@rotate]="languageFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="languageFilterStatus ? 'expand' : 'collapse'">
      <mh-core-lib-checkbox-input
          [options]="languageOptions"
          [displayOptionsFn]="displayLanguageFn"
          (optionClicked)="setReviewFilterState($event)">
      </mh-core-lib-checkbox-input>
        </div>
      </div>
  </div>
</div>
</ng-template>
