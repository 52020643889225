import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-core-lib-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class CoreLibToggleComponent {
  @Output() onClick: EventEmitter<{ id: number; value: boolean; name: string }> = new EventEmitter();
  @Input() label: string;
  @Input() isDisabled = false;
  @Input() inner = false;
  @Input() id = 0;
  @Input() name = '';
  @Input() preventClick = false;
  @Input() colors = ['tw-bg-main'];
  @Input() value;

  onClickEmit() {
    if (this.preventClick) {
      return;
    }
    this.value = !this.value;
    this.onClick.emit({ id: this.id, value: this.value, name: this.name });
  }

  get color() {
    if (this.colors.length > 1) return this.value ? this.colors[this.id] : 'tw-bg-gray-50';
    return this.value ? 'tw-bg-main' : 'tw-bg-gray-50';
  }
}
