import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mh-user-mention',
  templateUrl: './user-mention.component.html',
  styleUrls: ['./user-mention.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMentionComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  fileName: any;

  @Input()
  fileKey: any;

  @Input()
  files: any;

  @Input()
  users: any[];

  @Input()
  waitingDownload;

  @Input()
  encryptedUser = true;

  @Output()
  downloadFile = new EventEmitter<any>();

  ngOnInit(): void {
    const matches = this.text && this.text.match(/\@\[(.*?)\]/g) || [];
    matches.forEach((match: string) => {
      try {
          let match_hash = match.slice(2);
          match_hash = match_hash.slice(0, -1);
        if ( this.encryptedUser ) {
          const hashId = match_hash?.split(':') || '';
          const base64Id = +atob(hashId[1]);
          const hash = match;
          const user = this.users.find(user => user.id === base64Id);
          if (user) {
            this.text = this.text.replace(hash, `<span class="highlight-mention">${user.first_name} ${user.last_name}</span>`);
          }
        } else {
          this.text = this.text.replace(match, `<span class="highlight-mention">${match_hash}</span>`);
        }
      } catch {
        return;
      }
    });
  }

  get areFiles() {
    return this.files && this.files.length > 0;
  }

  get downloadStatus() {
    const file = this.waitingDownload.find(file => file.file_key === this.fileKey);
    return file?.downloading;
  }
}
