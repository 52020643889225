import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UtilService } from 'app/layouts/main';
import { StoreCurrentUsers } from 'app/states/users/users.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-users-states-handler',
  template: '<ng-content></ng-content>',
})
export class UsersStatesHandlerComponent implements OnInit, OnDestroy {
  subscription$: Subscription;

  constructor(private utilService: UtilService, private store: Store) {
    this.subscription$ = this.utilService.isCustomerChanged().subscribe(() => {
      this.setUsers();
    });
  }

  ngOnInit() {
    this.setUsers();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  setUsers() {
    this.store.dispatch(new StoreCurrentUsers(this.currentHotelId));
  }

  get currentHotelId() {
    return this.utilService.currentHotel.id;
  }
  get currentUserId() {
    return this.utilService.currentUser.id;
  }
}
