const customerPath = 'https://api-dev.myhotel.io/customers/';
const followupPath = 'https://api-dev.myhotel.io/followup/';
const onlinePath = 'https://api-dev.myhotel.io/online/';
const collectPath = 'https://api-dev.myhotel.io/collect/';
const reportsPath = 'https://api-dev.myhotel.io/reports/';
const authPath = 'https://api-dev.myhotel.io/security/';
const prestayPath = 'https://api-dev.myhotel.io/prestay/';
const surveysPath = 'https://api-dev.myhotel.io/surveys/';
const casesPath = 'https://api-dev.myhotel.io/cases/';
const notificationsPath = 'https://api-dev.myhotel.io/notifications/';
const surveyPublicPath = 'https://surveys-dev.myhotel.cl/';
const apiKey = 'Upy1BTFzD63LFLzlkeCy72gFJokPrUGC4ZLUtbIq';
const googleAnalitics = 'UA-121607990-1';
const googleAnalytics = 'G-5NKJEWTTGF';
const gtmContainerId = 'GTM-TB8D7GRT';
const widgets = 'https://www.myhotel.cl/';
const version = '1.0.26';
const googleAnalyticsGuest = 'G-06JV5H84S6';
const pubnubPublishKey = 'pub-c-7b895b1d-336c-49fd-a67d-8cb8a3dc15d9';
const pubnubSuscribeKey = 'sub-c-570e0ede-de69-4dd7-9675-a0443154c5b2';
const proxyHtml2Canvas = 'https://13dnnb8vlk.execute-api.us-west-1.amazonaws.com/dev/';
const mhFrontLib = 'https://fidelity-dev.myhotel.cl/mh-lib/index.html';
const facebookAppId = '1150132119078362';
const facebookAppSecret = 'a296d8c7cb730252e6f96e4f620d03ca';
const facebookAppCallback = 'https://fidelity-dev.myhotel.cl/online/integraciones/facebook/callback-handler';
const facebookApiVersion = 'v19.0';
const hubspotRegion = 'na1';
const hubspotPortalId = '23490592';
const hubspotFormIdEs = '5d64b39c-1e72-4845-b0d3-220770475350';
const hubspotFormIdEn = '7cae67be-eef3-49ce-8ee7-9fa99bd0575b';
const hubspotFormIdPt = 'a3c84085-a81f-4ea3-b28a-60e0f715fcd9';
const applicationRenderSurveyURL = 'https://develop.d2upyv14rz6rmo.amplifyapp.com/api/pdf';
const renderSurvey = 'https://develop.d2upyv14rz6rmo.amplifyapp.com/';

export const environment = {
  production: false,
  use_dummy: false,
  envName: 'development',

  customerPath: customerPath,
  followupPath: followupPath,
  onlinePath: onlinePath,
  collectPath: collectPath,
  reportsPath: reportsPath,
  prestayPath: prestayPath,
  securityPath: authPath,
  surveysPath: surveysPath,
  casesPath: casesPath,
  notificationsPath: notificationsPath,
  surveyPublicPath: surveyPublicPath,

  voicesPath: customerPath + 'voices',

  followupInterChainRankingAreas: followupPath + 'interchains/ranking/areas',
  followupInterChainNPS: followupPath + 'interchains/nps/metricts',
  followupInterChainPMS: followupPath + 'interchains/pms/emails',
  followupInterChainSemantic: followupPath + 'interchains/semantic/metricts',
  onlineInterChainRankingTripAdvisor: onlinePath + 'interchains/rankings',
  onlineInterChainNPS: onlinePath + 'interchains/nps/metricts',
  onlineInterChainTripadvisor: onlinePath + 'interchains/tripadvisor/summary',
  onlineInterChainSummary: onlinePath + 'interchains/main/summary',
  interChainHistoricNPS: followupPath + 'interchains/nps/metricts/historic/data',
  interChainHistoricEmails: followupPath + 'interchains/pms/emails/historic/data',
  interChainHistoricRanking: followupPath + 'interchains/ranking/areas/historic/data',
  interChainEmailsRanking: followupPath + 'interchains/surveys/emails/historic/data',
  interChaiHistoricTripAdvisor: onlinePath + 'interchains/tripadvisor/historic/data',
  interChaiCoverageRanking: onlinePath + 'interchains/coverage/by_ratings',
  interChaiCoverageCustomers: onlinePath + 'interchains/coverage/by_customer',
  interChaiCoverageGeneral: onlinePath + 'interchains/coverage/general',
  interChaiCoverageExecutive: onlinePath + 'interchains/executive',
  interChaiCoverageCollect: onlinePath + 'interchains/collect/comments/summary',
  corporativeChaiCollect: collectPath + 'corporative',

  collectCustomers: collectPath + 'customers',

  followUpSummarySurveys: followupPath + 'summary/surveys',
  followUpSentSurveyUrl: followupPath + 'summary/surveys/sent',
  followUpGeneralSatisfactionUrl: followupPath + 'summary/surveys/nps/metricts',
  followUpTripadvisor: followupPath + 'summary/surveys/tripadvisor_reviews',
  followUpOverallSatisfactionUrl: followupPath + 'summary/surveys/guests/satisfactions',
  followUpSatisfactionQuestionConceptUrl: followupPath + 'summary/surveys/satisfactions/questions/concepts',
  followUpSatisfactionFirstQuestion: followupPath + 'summary/surveys/satisfactions/first_questions',
  followUpCommentFeedsUrl: followupPath + 'summary/surveys/comments/feeds',
  followUpCommentSurvey: followupPath + 'summary/surveys/comments/feeds_survey',
  followUpAnswerSurveyUrl: followupPath + 'surveys/answereds/',
  followUpPmsUrl: followupPath + 'guests/emails',
  followUpRankingByAreaUrl: followupPath + 'summary/surveys/ranking/by_areas',
  followUpRankingByAreaAverages: followupPath + 'summary/surveys/ranking/areas/averages',
  surveyGeneralSatisfaction: followupPath + 'surveys/general/satisfactions/by_question',
  followUpRankingByGroupPeriodsUrl: followupPath + 'surveys/ranking/areas/group_by_periods',

  followupAlert: followupPath + 'alerts',
  onsiteAlert: followupPath + 'alerts',
  onlineAlert: onlinePath + 'alerts',

  onlineOtasUrl: onlinePath + 'summary/reviews/ranking/otas',
  onlineOtasHistoricUrl: onlinePath + 'summary/reviews/averages/historic/otas',
  onlineOtasActive: onlinePath + 'otas/actives',
  onlineListReviewsUrl: onlinePath + 'reviews/list',
  onlinePricingUrl: onlinePath + 'pricing/customer_competitors',
  onlineBenchmark: onlinePath + 'benchmark/info_score_reviews_area',
  onlineBenchmarkResume: onlinePath + 'benchmark/resume_score_reviews_area',
  onlineBenchmarkTrend: onlinePath + 'benchmark/graph_info_score_reviews_area',
  onlineDistributionReview: onlinePath + 'reviews/list',
  onlineDemographyReview: onlinePath + 'reviews/demography/reviews',
  onlineReviewsNpsScale: onlinePath + 'reviews/nps/scale',
  onlineReviews: onlinePath + 'reviews',
  onlineCompetitors: onlinePath + 'competitors/list_competitors',
  onlineListReviewsJson: 'src/app/resource/followUp/reviews.json',
  onlineCompetitorsJSON: 'src/app/resource/online/onlineCompetitors.json',

  customerById: customerPath,
  customerByIds: customerPath + 'customers',
  customerCurrencies: customerPath + 'currencies',
  customersByFlag: customerPath + 'flag/client/',
  customerProfile: customerPath + 'users/profile_access',
  customerUsers: customerPath + 'users/profiles_access',
  customerUser: customerPath + 'users',
  customerFlag: '1',
  customerSummaries: customerPath + 'summaries',
  customerTranslateObject: customerPath + 'translate',
  superAdminProperties: customerPath + 'users/superadmin/property',
  email: customerPath + 'emails',

  semanticOnlineTheme: onlinePath + 'semantic/theme',
  semanticOnlinePhrase: onlinePath + 'semantic/phrase',
  semanticOnlineEntity: onlinePath + 'semantic/entity',
  semanticOnlineAreas: onlinePath + 'semantic/areas',
  semanticOnlineArea: onlinePath + 'semantic/area',

  semanticFollowupTheme: followupPath + 'semantic/theme',
  semanticFollowupPhrase: followupPath + 'semantic/phrase',
  semanticFollowupEntity: followupPath + 'semantic/entity',
  semanticFollowupAreas: followupPath + 'semantic/areas',
  semanticFollowupArea: followupPath + 'semantic/area',

  reportManagementUrl: reportsPath + 'reports/management',
  reportEmail: reportsPath + 'reports/email',
  reportPrestay: reportsPath + 'reports/prestay',
  myhotel_api_key: apiKey,
  loginPath: authPath + 'login',
  googleAnaliticsKey: googleAnalitics,
  googleAnalyticsKey: googleAnalytics,
  googleAnalyticsGuest: googleAnalyticsGuest,
  gtmContainerId: gtmContainerId,
  loginWidget: widgets + 'widget-login',
  widgets: widgets,
  version: version,
  pubnubPublishKey: pubnubPublishKey,
  pubnubSuscribeKey: pubnubSuscribeKey,
  proxyHtml2Canvas: proxyHtml2Canvas,
  mhFrontLib: mhFrontLib,
  facebookAppId,
  facebookAppSecret,
  facebookAppCallback,
  facebookApiVersion,
  hubspotRegion,
  hubspotPortalId,
  hubspotFormIdEs,
  hubspotFormIdEn,
  hubspotFormIdPt,
  applicationRenderSurveyURL,
  renderSurvey,
};
