import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'app/_services';
import { User } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-datepicker',
  templateUrl: './core-lib-datepicker.component.html',
  styleUrls: ['./core-lib-datepicker.component.scss']
})
export class CoreLibDatepickerComponent implements OnInit {

  rows = []
  previousMonth = [];
  currentMonth = [];
  nextMonth = [];
  reachedPreviousDayMonth = false;
  reachedFinalDayMonth = false;
  calendar = [];
  nextCalendar = false;
  noPushNext = false;

  isSelectingRange = false;
  isHovering = false;
  currentHoveredDate: any;

  showCalendar = false;
  firstMonth = false;
  lastYear = false;
  showRangeError = false;
  maxDays: number = 365;

  currentFilterSelected: any;
  inputs = [
    false,
    false,
    false,
    false
  ];

  filterOptions = [
    {label: 'filters.dates.lastSevenDays', obj: {days: 7}, selected: false},
    {label: 'filters.dates.lastThirtyDays', obj: {days: 30}, selected: true},
    {label: 'filters.dates.thisMonth', obj: {month: 'currentMonth'}, selected: false},
    {label: 'filters.dates.lastMonth', obj: {month: 'lastMonth'}, selected: false},
    {label: 'filters.dates.lastThreeMonths', obj: {month: 'last3'}, selected: false},
    {label: 'filters.dates.yearToDate', obj: {year: 'beginning'}, selected: false},
    {label: 'filters.dates.custom', obj: {custom: 'custom'}, selected: false}
  ];

  months = [
      {label: 'datepicker.months.0', value: 1},
      {label: 'datepicker.months.1', value: 2},
      {label: 'datepicker.months.2', value: 3},
      {label: 'datepicker.months.3', value: 4},
      {label: 'datepicker.months.4', value: 5},
      {label: 'datepicker.months.5', value: 6},
      {label: 'datepicker.months.6', value: 7},
      {label: 'datepicker.months.7', value: 8},
      {label: 'datepicker.months.8', value: 9},
      {label: 'datepicker.months.9', value: 10},
      {label: 'datepicker.months.10', value: 11},
      {label: 'datepicker.months.11', value: 12}
    ];

    days = [
      {label: 'datepicker.weekdays.0'},
      {label: 'datepicker.weekdays.1'},
      {label: 'datepicker.weekdays.2'},
      {label: 'datepicker.weekdays.3'},
      {label: 'datepicker.weekdays.4'},
      {label: 'datepicker.weekdays.5'},
      {label: 'datepicker.weekdays.6'},
    ]


  dateSelected: DateTime;
  finalDateSelected: DateTime;
  readonly MIN_YEAR = 1900;
  firstInputMonth = '';
  secondInputMonth = '';
  firstInputYear = DateTime.now().year;
  secondInputYear = DateTime.now().year;

  selectedFilter = '';

  currentSelectedMonth: number = DateTime.now().month;
  nextSelectedMonth: number = DateTime.now().month;
  currentSelectedYear = DateTime.now().year;
  nextSelectedYear = DateTime.now().year;

  datepickerMobileActive = false;

  @Input()
  currentDates = null;

  @Input()
  currentFilter = null;

  @Input()
  isTrend = false;

  @Input()
  singleDatePicker: boolean = false;

  @Output()
  dateUpdated = new EventEmitter();

  @Output()
  filterUpdated = new EventEmitter();

  @Output()
  datepickerUpdated = new EventEmitter();
  languageSubscription: Subscription;
  currentLanguage: any;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      event.target.className.includes('core-datepicker-header-filter-options') ||
      event.target.className.includes('date-content')
    ) {
      return;
    }

    if ( !event.target.className.includes('core-datepicker-header-button' ) &&
        !event.target.className.includes('fa-angle-down')
    ) {
      this.toggleInputs(null, null, true);
    }

    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showCalendar = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private dateService: DateService,
    private utilService: UtilService
  ) {
    this.currentLanguage = this.utilService.currentLanguage;
   }

  ngOnInit(): void {
    this.init();
    this.languageSubscription = this.utilService.isLanguageChanged().subscribe(language => {
      this.currentLanguage = language;
    });
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe;
  }

  init() {
    if ( this.singleDatePicker ) {
      this.createCalendar();
    } else {
      this.setQueryParamsDate();
      const option = (this.router.url.includes('invoice') || this.router.url.includes('tendencia')|| this.isTrend) ?
        {label: 'filters.dates.custom', obj: {custom: 'custom'}, selected: true} :
        this.getFilter() ?
        this.getFilter() :
        {label: 'filters.dates.lastThirtyDays', obj: {days: 30}, selected: true};

      this.handleClickFilter(option, 'init');
      this.setMonths();
    }
  }

  setQueryParamsDate() {
    const {
      start_date,
      end_date,
      date
    } = this.route.snapshot.queryParams;
    if (start_date && end_date) {
      this.currentDates = {
        startDate: DateTime.fromISO(start_date),
        endDate: DateTime.fromISO(end_date)
      }
      this.currentFilter = {label: 'filters.dates.custom', obj: {custom: 'custom'}, selected: true};
    } else if ( date ) {
      this.currentDates = {
        startDate: DateTime.fromISO(date),
        endDate: DateTime.fromISO(date)
      }
      this.currentFilter = {label: 'filters.dates.custom', obj: {custom: 'custom'}, selected: true};
    }
  }

  getFilter() {
    if (this.currentFilter.label) {
      return this.filterOptions.find(filter => filter.label === this.currentFilter.label);
    }
  }

  setMonths() {
    if (!this.dateSelected && !this.finalDateSelected) {
      const month: any = this.months.find(month => month.value === DateTime.now().month);
      const nextMonth: any = this.months.find(month => month.value === DateTime.now().month);
      this.firstInputMonth = month.label;
      this.secondInputMonth = nextMonth.label;
      this.currentSelectedMonth = month.value;
      this.nextSelectedMonth = nextMonth.value;
    } else {
      const month: any = this.months.find(month => month.value === this.dateSelected.month);
      const nextMonth: any = this.months.find(month => month.value === this.finalDateSelected.month);
      this.firstInputMonth = month.label;
      this.secondInputMonth = nextMonth.label;
      this.currentSelectedMonth = month.value;
      this.nextSelectedMonth = nextMonth.value;
    }
  }

  /*
   * @Params
   * month: si el input es un mes
   * year: si el input es de un año
   * value: valor a considerar de los inputs seleccionables
   * sec: si los inputs son del segundo calendario
   */
  createCalendar(month = false, year = false, value = null, sec = false, source = 'input') {

    if ( source === 'input' && sec ) {
      this.calendar.splice(1, 1);
    } else if ( source === 'input' && !sec ) {
      this.calendar.splice(0, 1);
    } else {
      this.nextCalendar = false;
      this.calendar = [];
    }

    let limit = (source === 'input' && this.calendar.length === 1) ?
      1 : 2;

    limit = (this.singleDatePicker) ? 1 : limit;

    for(let cal = 0; cal < limit; cal++) {

      let currentMonth: any;


      let prevYear: any;
      let thisYear: any;
      let nextYear: any;

      let prevMonth: any;
      let nextMonth: any;

      if (this.dateSelected && this.finalDateSelected) {

        let newDate: any;

        if ( cal === 0 ) {
          newDate = DateTime.fromObject({
            day: this.dateSelected.day,
            month: this.dateSelected.month,
            year: this.dateSelected.year
          });
        } else {
          newDate = DateTime.fromObject({
            day: this.finalDateSelected.day,
            month: this.finalDateSelected.month,
            year: this.finalDateSelected.year
          });
        }

        // y, finalmente, setear en los inputs los valores indicados según los filtros

        currentMonth = newDate.month;
        if ( sec || this.nextCalendar ) {
          currentMonth = this.nextSelectedMonth;
        } else {
          currentMonth = this.currentSelectedMonth;
        }

      } else {

        currentMonth = (this.nextCalendar) ?
          DateTime.now().month + 1 :
          DateTime.now().month;

        if ( currentMonth === 13 ) {
          currentMonth = 1;
        }


        if ( year ) {
          currentMonth = ( sec ) ?
            this.nextSelectedMonth :
            this.currentSelectedMonth;
        }

        if ( month ) {
          if ( sec || this.nextCalendar ) {
            currentMonth = this.nextSelectedMonth;
          } else {
            currentMonth = this.currentSelectedMonth;
          }
        }
      }

      const inputMonth = this.months.find(month => month.value === currentMonth);
      if ( limit === 1 ) {
        if (sec) {
          this.secondInputMonth = inputMonth.label;
        } else {
          this.firstInputMonth = inputMonth.label;
        }
      } else {
        if ( cal === 0 ) {
          this.firstInputMonth = inputMonth.label;
        } else {
          this.secondInputMonth = inputMonth.label;
        }
      }

      if ( this.nextCalendar && currentMonth === 1 ) {
        this.secondInputYear = this.firstInputYear + 1;
      }

      this.rows = [];

      prevYear = (currentMonth === 1) ?
        (sec) ? (this.secondInputYear - 1) : (this.firstInputYear - 1) :
        (sec) ? (this.secondInputYear) : this.firstInputYear;



      thisYear = ( !sec ) ?
        this.firstInputYear :
        this.secondInputYear;


      let tmpInputYear = (!sec) ?
        this.firstInputYear :
        this.secondInputYear;

      if ( source === 'reset' ) {
        if ( !this.nextCalendar ) {
          thisYear = this.firstInputYear;
          tmpInputYear = this.firstInputYear;
        } else {
          thisYear = this.secondInputYear;
          tmpInputYear = this.secondInputYear;
        }
      }

      nextYear = (currentMonth === 12) ?
        (tmpInputYear + 1) :
        tmpInputYear;

      prevMonth = ( currentMonth === 1 ) ?
        12 :
        currentMonth - 1;

      nextMonth = ( currentMonth === 12 ) ?
        1 :
        currentMonth + 1;

      const firstDayInWeek = DateTime.local(thisYear, currentMonth, 1).weekday;

      // día desde el cual se muestra el mes pasado
      let pastMonthFromDay = DateTime.fromObject({month: currentMonth}).minus({days: firstDayInWeek - 1}).day

      // día máximo del mes actual
      const maxDayInMonth = DateTime.local(thisYear, currentMonth).daysInMonth;
      
      this.previousMonth = []
      this.currentMonth = []
      this.nextMonth = []

      let currentDay = 1;
      let nextMonthCurrentDay = 1;



      for (let week = 0; week < 7; week++) {
        this.previousMonth = [];
        this.currentMonth = [];
        this.nextMonth = [];
        this.noPushNext = false;

        for (let day = 1; day <= 7; day++) {
          if (day < firstDayInWeek && !this.reachedPreviousDayMonth) {
            // lógica para calcular el rellando del mes anterior
            const pastDay = pastMonthFromDay;
            pastMonthFromDay++;
            const date = {
              day: pastDay,
              date: DateTime.fromObject({month: prevMonth, day: pastDay, year: prevYear})
            };
            this.previousMonth.push(date);
          } else if (currentDay <= maxDayInMonth) {
            // mes actual
            this.reachedPreviousDayMonth = true;
            const date = {
              day: currentDay,
              date: DateTime.fromObject({month: currentMonth, day: currentDay, year: thisYear})
            }
            this.currentMonth.push(date);
            // condición para no mostrar el siguiente mes si el día máximo es domingo
            if (currentDay === maxDayInMonth && day === 7) {
              this.noPushNext = true;
              this.reachedFinalDayMonth = true;
            }
            currentDay += 1;
          } else {
            // solo suma días al mes siguiente
            this.reachedFinalDayMonth = true;
            const date = {
              day: nextMonthCurrentDay,
              date: DateTime.fromObject({day: nextMonthCurrentDay, month: nextMonth, year: nextYear})
            }
            this.nextMonth.push(date);
            nextMonthCurrentDay += 1;
          }
        }

        // fila de un mes, semanas básicamente
        const row = {
          previous: this.previousMonth,
          current: this.currentMonth,
          next: (this.noPushNext) ? [] : this.nextMonth
        }
        this.rows.push(row);

        if (this.reachedFinalDayMonth) {
          break;
        }
      }


      // reiniciamos la lógica para el siguiente mes
      this.reachedFinalDayMonth = false;
      this.reachedPreviousDayMonth = false;
      this.nextCalendar = true;

      if ( source === 'input' && !sec ) {
        this.calendar.splice(0, 0 , this.rows);
      } else {
        this.calendar.push(this.rows)
      }
    }
  }

  compareDates(inputDate: DateTime) {
    if ( this.isSelectingRange ) {
      this.isHovering = true;
      this.currentHoveredDate = inputDate;
    }
  }

  resetValues() {
    this.isSelectingRange = false;
    this.dateSelected = null;
    this.finalDateSelected = null;
    this.currentHoveredDate = null;
    this.nextCalendar = false;
  }

  handleClick(inputDate: DateTime, source, whichCalendar: any) {
    if ( this.dateSelected && this.finalDateSelected ) {
      this.resetValues();
    }
    if ( this.dateSelected && this.dateSelected.startOf('day') > inputDate.startOf('day') ) {
      this.resetValues();
    }
    if ( this.isSelectingRange && this.dateSelected ) {

      this.finalDateSelected = inputDate;
      this.isSelectingRange = false;

      const startDate = this.dateSelected.toFormat('yyyy-LL-dd');
      const endDate = this.finalDateSelected.toFormat('yyyy-LL-dd');

      const diffTime = this.finalDateSelected.diff(this.dateSelected, 'days');
      const totalDays = diffTime.toObject().days;

      if(totalDays <= this.maxDays || this.currentUser.isSuperAdmin()) {
        this.showRangeError = false;
        this.datepickerUpdated.emit({
          currentDates: {startDate, endDate},
          currentFilter: {label: 'filters.dates.custom'}
        });
        this.showCalendar = false;
      } else {
        this.showRangeError = true;
      }

      const month = this.months.find(month => month.value === inputDate.month);
      this.nextSelectedMonth = month.value

      this.secondInputMonth = this.months.find(month => month.value === this.nextSelectedMonth).label;
      this.secondInputYear = inputDate.year;
      this.nextSelectedYear = inputDate.year;
      this.createCalendar(true, false, this.nextSelectedMonth, true);

      this.dateSelected = DateTime.fromObject({
        day: this.dateSelected.day,
        month: this.dateSelected.month,
        year: this.currentSelectedYear
      })

      this.finalDateSelected = DateTime.fromObject({
        day: this.finalDateSelected.day,
        month: this.finalDateSelected.month,
        year: this.nextSelectedYear
      });

    } else {

      if ( this.currentSelectedMonth > inputDate.month && this.currentSelectedYear >= inputDate.year ) {
        whichCalendar = 0;
      }

      this.isSelectingRange = true;
      this.dateSelected = inputDate;
      const filter = this.filterOptions.find(filter => filter.label === 'filters.dates.custom');
      this.selectedFilter = filter.label;
      if ( !filter.selected ) {
        const filterSelected = this.filterOptions.find(filter => filter.selected);
        if ( filterSelected ) {
          filterSelected.selected = false;
        }
        filter.selected = true;
      }



      // Esta misma lógica tiene que ser aplicable para el segundo calendario. Hay que utilizar las variables
      if ( source === 'prev' ) {

        this.currentSelectedMonth = inputDate.month;
        if ( !whichCalendar ) {
          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;
          this.currentSelectedYear = inputDate.year;
          this.firstInputYear = inputDate.year;
          this.nextCalendar = false;
          this.createCalendar(true, false, this.currentSelectedMonth, false);
        } else {

          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;

          this.currentSelectedYear = inputDate.year;
          this.firstInputYear = inputDate.year;
          this.nextCalendar = false;
          this.createCalendar(true, false, this.currentSelectedMonth, false);
        }
      } else if ( source === 'next' ) {
        const month = this.months.find(month => month.value === inputDate.month);

        this.currentSelectedMonth = month.value

        if ( !whichCalendar ) {

          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;
          this.firstInputYear = inputDate.year;
          this.currentSelectedYear = inputDate.year;
          this.nextCalendar = false;
          this.createCalendar(true, false, this.currentSelectedMonth, false);

        } else {

          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;
          this.firstInputYear = inputDate.year;
          this.currentSelectedYear = inputDate.year;


          const tmpMonth = (this.currentSelectedMonth === 12) ? 1 : this.currentSelectedMonth + 1;
          const tmpYear = (this.currentSelectedMonth === 12) ? inputDate.year + 1 : inputDate.year;

          this.secondInputMonth = this.months.find(month => month.value === tmpMonth).label;
          this.nextSelectedMonth = tmpMonth;
          this.secondInputYear = tmpYear;
          this.nextSelectedYear = tmpYear;

          this.createCalendar(true, false, this.currentSelectedMonth, false, 'reset');
        }
      } else if ( source === 'current' ) {

        if ( !whichCalendar ) {

          this.currentSelectedMonth = inputDate.month;
          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;
          this.currentSelectedYear = inputDate.year;


          // aca hay que setear los valores del calendario 2 sin modificarlos
        } else {
          this.currentSelectedMonth = inputDate.month;
          this.firstInputMonth = this.months.find(month => month.value === this.currentSelectedMonth).label;
          this.currentSelectedYear = inputDate.year;
          this.firstInputYear = inputDate.year;

          this.nextSelectedMonth = (inputDate.month === 12) ? 1 : inputDate.month;
          const month = this.months.find(month => month.value === ((inputDate.month === 12) ? 1 : this.nextSelectedMonth));
          this.secondInputMonth = month.label;
        }

        this.nextCalendar = false;
        this.createCalendar(true, false, this.currentSelectedMonth, false);

      }
    }
  }

  handleClickFilter(option, source = 'filter') {

    this.resetValues();

    const filter = this.filterOptions.find(filter => filter.label === option.label);
    this.selectedFilter = filter.label;
    if ( !filter.selected ) {
      const filterSelected = this.filterOptions.find(filter => filter.selected);
      if ( filterSelected ) {
        filterSelected.selected = false;
      }
      filter.selected = true;
    }

    if ( option.obj && option.obj.month ) {
      if (option.obj.month === 'currentMonth') {
        const currentMonth = DateTime.now().month;
        this.firstInputYear = DateTime.now().year;
        this.dateSelected = DateTime.fromObject({day: 1, month: currentMonth});
        this.finalDateSelected = DateTime.now()
        this.secondInputYear = DateTime.now().year;
      }
      if (option.obj.month === 'lastMonth') {
        const lastMonth = (DateTime.now().month === 1) ? 12 : DateTime.now().month - 1;
        this.dateSelected = (lastMonth === 12) ?
          DateTime.fromObject({day: 1, month: lastMonth, year: DateTime.now().year - 1}) :
          DateTime.fromObject({day: 1, month: lastMonth});
        this.finalDateSelected = (lastMonth === 12) ?
          DateTime.fromObject({day: this.dateSelected.daysInMonth, month: lastMonth, year: DateTime.now().year - 1}) :
          DateTime.fromObject({day: this.dateSelected.daysInMonth, month: lastMonth})
        this.firstInputYear = this.dateSelected.year;
        this.secondInputYear = this.finalDateSelected.year;
      }
      if (option.obj.month === 'last3') {
        this.dateSelected = DateTime.now().minus({weeks: 12});
        this.finalDateSelected = DateTime.fromObject({month: DateTime.now().month, day: DateTime.now().day})

        this.firstInputYear = this.dateSelected.year;
        this.secondInputYear = this.finalDateSelected.year;
      }
    } else if (option.obj && option.obj.year) {
      this.dateSelected = DateTime.fromObject({day: 1, month: 1});
      this.finalDateSelected = DateTime.fromObject({month: DateTime.now().month, day: DateTime.now().day});
      this.firstInputYear = this.dateSelected.year;
      this.secondInputYear = this.finalDateSelected.year;
    } else if (option.obj && option.obj.custom) {

      this.dateSelected = (this.currentDates && source === 'init') ?
        DateTime.fromISO(this.currentDates.startDate) : null;
      this.finalDateSelected = (this.currentDates && source === 'init') ?
        DateTime.fromISO(this.currentDates.endDate) : null;

      if ( this.router.url.includes('tendencia') || this.isTrend ) {
        this.dateSelected = DateTime.now().minus({years:1});
        this.finalDateSelected = DateTime.now();
        const startDate = this.dateSelected.toFormat('yyyy-LL-dd');
        const endDate = this.finalDateSelected.toFormat('yyyy-LL-dd');
        this.dateService.setTrendCurrentDates({ startDate, endDate });
      }

      if ( this.router.url.includes('invoice')) {
        this.dateSelected = DateTime.now().minus({weeks: 24});
        this.finalDateSelected = DateTime.fromObject({month: DateTime.now().month, day: DateTime.now().day});
      }
      this.firstInputYear = this.dateSelected?.year || DateTime.now().year;
      this.secondInputYear = this.finalDateSelected?.year || DateTime.now().year;


      this.datepickerMobileActive = true;
    } else {
      this.dateSelected = DateTime.now().minus(option.obj);
      if ( this.dateSelected.startOf('day') < DateTime.now().startOf('day') ) {
        this.finalDateSelected = DateTime.now();
      } else {
        this.finalDateSelected = DateTime.fromObject({month: this.dateSelected.month + 1});
      }
      this.firstInputYear = this.dateSelected.year;
      this.secondInputYear = this.finalDateSelected.year;
    }

    if ( this.dateSelected && this.finalDateSelected ) {

      const startDate = this.dateSelected.toFormat('yyyy-LL-dd');
      const endDate = this.finalDateSelected.toFormat('yyyy-LL-dd');

      this.datepickerUpdated.emit({
        currentDates: {startDate, endDate},
        currentFilter: {label: filter.label}
      });
    }

    this.setMonths();
    this.currentHoveredDate = this.finalDateSelected;
    source = (source === 'filter') ? 'input' : source;
    this.nextCalendar = false;
    this.calendar = [];
    this.createCalendar(false, false, null, false, 'reset');
    if ( option.obj && !option.obj.custom ) {
      this.showCalendar = false;
    }
  }

  toggleInputs(source, index, closeAll = false) {
    if ( closeAll ) {
      this.inputs = this.inputs.map(input => false);
      return;
    }
    if ( source === 'first' && index === 0 ) {
      //primer input
      this.inputs[0] = !this.inputs[0];
      this.inputs[1] = false;
      this.inputs[2] = false;
      this.inputs[3] = false;
    }
    if ( source === 'last' && index === 0 ) {
      // segundo input
      this.inputs[1] = !this.inputs[1];
      this.inputs[0] = false;
      this.inputs[2] = false;
      this.inputs[3] = false;
    }
    if ( source === 'last' && index === 1 ) {
      // tercer input
      this.inputs[2] = !this.inputs[2];
      this.inputs[0] = false;
      this.inputs[1] = false;
      this.inputs[3] = false;
    }
    if ( source === 'first' && index === 1 ) {
      // cuarto input
      this.inputs[3] = !this.inputs[3];
      this.inputs[0] = false;
      this.inputs[1] = false;
      this.inputs[2] = false;
    }
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
    this.inputs[0] = false;
    this.inputs[1] = false;
    this.inputs[2] = false;
    this.inputs[3] = false;
  }

  setDateFromInput(date, source, index) {

    if ( this.dateSelected && this.finalDateSelected ) {
      this.resetValues();
    }
    const filter = this.filterOptions.find(filter => filter.label === 'filters.dates.custom');
    this.selectedFilter = filter.label;
    if ( !filter.selected ) {
      const filterSelected = this.filterOptions.find(filter => filter.selected);
      if ( filterSelected ) {
        filterSelected.selected = false;
      }
      filter.selected = true;
    }
    if ( source === 'first' && index === 0 ) {
      //primer input
      this.nextCalendar = false;
      this.inputs[0] = !this.inputs[0];
      this.firstInputMonth = date.label;
      this.currentSelectedMonth = date.value;
      this.createCalendar(true, false, date.value, false);
    }
    if ( source === 'last' && index === 0 ) {
      // segundo input
      this.inputs[1] = !this.inputs[1];
      this.firstInputYear = date;
      this.currentSelectedYear = date;
      this.createCalendar(false, true, date, false);
    }
    if ( source === 'last' && index === 1 ) {
      // tercer input
      this.inputs[2] = !this.inputs[2];
      this.secondInputMonth = date.label;
      this.nextSelectedMonth = date.value;
      this.createCalendar(true, false, date.value, true);
    }
    if ( source === 'first' && index === 1 ) {
      // cuarto input
      this.inputs[3] = !this.inputs[3];
      this.secondInputYear = date;
      this.nextSelectedYear = date;
      this.createCalendar(false, true, date, true);
    }

  }

  toggleFiltersMobile() {
    this.datepickerMobileActive = false;
  }

  get initDate() {
    if ( this.dateSelected ) {
      return this.dateSelected.toLocaleString(DateTime.DATETIME_MED)
    } else {
      return ''
    }

  }

  get endDate() {
    if (this.finalDateSelected) {
      return this.finalDateSelected.toLocaleString(DateTime.DATETIME_MED)
    } else {
      return '';
    }
  }

  get years() {
    const years = [];
    for ( let i = DateTime.now().year; i >= this.MIN_YEAR; i-- ) {
      years.push(i);
    }
    return years;
  }


  get dateSelectedFormated(): string {
    return this.dateSelected ? new Intl.DateTimeFormat(this.currentLanguage, { dateStyle: 'full' }).format(this.dateSelected.toJSDate()) : '';
  }

  get dateSelectedFormatedBtn(): string {
    return this.dateSelected ? new Intl.DateTimeFormat(this.currentLanguage, { dateStyle: 'short' }).format(this.dateSelected.toJSDate()) : '';
  }

  get finalDateSelectedFormated(): string {
    return this.finalDateSelected ? new Intl.DateTimeFormat(this.currentLanguage, { dateStyle: 'full' }).format(this.finalDateSelected.toJSDate()) : '';
  }

  get finalDateSelectedFormatedBtn(): string {
    return this.finalDateSelected ? new Intl.DateTimeFormat(this.currentLanguage, { dateStyle: 'short' }).format(this.finalDateSelected.toJSDate()) : '';
  }

  get selectedDates(): string {
    return (this.dateSelected && this.finalDateSelected) ?
      `${this.dateSelectedFormated} - ${this.finalDateSelectedFormated}` :
      '';
  }

  get currentUser():User{
    return this.sessionService.getCurrentUser();
  }
}
