<article>
  <section *ngIf="messages.length === 0" class="tw-flex tw-items-center tw-flex-col tw-mt-5 lg:tw-mt-10">
    <p class="tw-font-bold tw-text-xl tw-text-center lg:tw-text-left">{{ 'answer_survey.history.no_register' | translate }}</p>
    <img
      class="tw-w-56 tw-mt-10"
      src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/empty-messages.png"
      alt="empty messages image"
    />
  </section>

  <section class="tw-mt-5 lg:tw-mt-10 tw-p-4" *ngIf="showHistory">
    <ng-container *ngFor="let message of messages">
      <div
        class="tw-flex tw-items-center tw-text-sm tw-cursor-pointer tw-px-7"
        (click)="showMessage(message)"
      >
        <div class="tw-flex tw-items-center tw-gap-4 tw-w-40">
          <div
            class="tw-flex tw-flex-none tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-rounded-full tw-border-gray-300 tw-text-center tw-bg-sky-500"
          >
            <i
              class="tw-text-slate-50 tw-text-sm tw-leading-none {{ channelIcons[message?.management_channel?.id] }}"
              [ngClass]="{ 
                            'tw-text-white': survey?.managed, 
                            'fal fa-minus tw-text-gray-300': !survey?.managed && !isNewMessage,
                        }"
            ></i>
          </div>
          <p class="tw-font-medium tw-text-sm">{{ message?.guest_name }}</p>
        </div>
        <div class="tw-max-w-md tw-ml-2 tw-hidden lg:tw-block">
          <p class="tw-truncate tw-text-sm">
            {{
              message?.contact_subject ||
                message?.contact_minute ||
                ('answer_survey.contact_dialog.no_message' | translate)
            }}
          </p>
        </div>
        <p class="tw-text-sm tw-ml-auto">{{ message?.action_date | date : 'dd/MM/y' }}</p>
      </div>
      <hr class="tw-border-t-2 tw-bg-gray-300 tw-my-3" />
    </ng-container>
  </section>

  <section class="tw-mt-5 lg:tw-mt-10" *ngIf="selectedMessage">
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-6">
      <div>
        <p class="tw-font-medium tw-text-lg">{{ 'answer_survey.history.selected_msg_title' | translate: { channel: ('answer_survey.channels.' + selectedMessage?.management_channel?.id) | translate } }}</p>
        <p
          [innerHTML]="
            'answer_survey.history.sent_info'
              | translate
                : {
                    name: (selectedMessage?.guest_name),
                    channel: ('answer_survey.channels.' + selectedMessage?.management_channel?.id) | translate,
                    date: (selectedMessage?.action_date | date : 'longDate'),
                    time: (selectedMessage?.action_date | date : 'h:mm')
                  }
          "
          class="tw-text-gray-500 tw-text-sm"
        ></p>
      </div>
      <div>
        <span
          class="tw-bg-gray-100 tw-rounded-full tw-px-5 tw-py-1 tw-text-base tw-cursor-pointer tw-z-20"
          (click)="back()"
          >{{ 'commons.back' | translate }}</span
        >
      </div>
    </div>
    <hr class="tw-border-t-2 tw-bg-gray-300 tw-my-3" />
    <div class="tw-mt-6">
      <p class="tw-whitespace-pre-line tw-w-11/12">{{ selectedMessage?.contact_minute }}</p>
    </div>
  </section>
</article>
