import { Component, OnInit, HostListener, Input } from '@angular/core';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { CustomerService } from 'app/_services';
import { UtilService } from 'app/shared/util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mh-competitors-selector',
  templateUrl: './competitors-selector.component.html',
  styleUrls: ['./competitors-selector.component.scss'],
})
export class CompetitorsSelectorComponent implements OnInit {
  @Select(CustomersState.currentCustomer) currentCustomerChanged$: Observable<any[]>;
  public customerChangedSub: Subscription;

  public competitors = null;
  public isOpen = false;
  public hasActiveFilters = false;
  public competitorSelected: string = this.translateService.instant('core-lib.selectors.competitors.default');

  @Input()
  isAirline = false;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  autocompleteFilterFn = (options, value) => {
    return options.filter((option: any) => {
      return option.name.toLowerCase().includes(value.toLowerCase());
    });
  };

  displayHotelOptions = (option) => {
    return `${option.name}`;
  };

  constructor(
    private customerService: CustomerService,
    private utilService: UtilService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initCompetitors();
    this.initListener();
  }

  initListener() {
    this.customerChangedSub = this.currentCustomerChanged$.subscribe(() => this.initCompetitors());
  }

  async initCompetitors() {
    try {
      const res = await this.customerService.getCompetitors(this.utilService.currentHotel.id).toPromise();
      this.competitorSelected = this.translateService.instant('core-lib.selectors.competitors.default');
      this.competitors = res;
    } catch (err) {
      console.error(err);
    }
  }

  checkClickInside(target) {
    if (target.id === 'overlaySelectorCompetitor' || target.id === 'selectorCompetitor') {
      return true;
    }
    if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    }
    return false;
  }

  handleCompetitorSelected(competitor: Competitor) {
    this.competitorSelected = competitor.name;
    this.hasActiveFilters = true;
    this.utilService.competitorsChanged(competitor);
  }

  cleanFilters() {
    const competitors = this.competitors.filter(
      (competitor) => competitor.name === this.utilService.currentHotel.name,
    )[0];
    this.competitorSelected = competitors.name;
    this.utilService.competitorsChanged(competitors);
    this.hasActiveFilters = false;
  }

  ngOnDestroy(): void {
    this.customerChangedSub.unsubscribe();
  }
}

export interface Competitor {
  id: number;
  name: string;
}
