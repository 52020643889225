import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuestSelectorService {
  survey = new Subject();
  guest = new Subject();
  activateClearFilter = false;

  updateGuestSelected(survey) {
    this.survey.next(survey);
  }

  guestSelected() {
    return this.survey.asObservable();
  }

  updateGuestsOptions(data) {
    this.guest.next(data);
  }

  guestOptions() {
    return this.guest.asObservable();
  }
}
