import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UsersActionsState } from 'app/states/users-actions/users-actions.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-actions-wrapper',
  templateUrl: './actions-wrapper.component.html',
  styleUrls: ['./actions-wrapper.component.scss'],
})
export class CoreLibActionsWrapperComponent implements OnInit, OnDestroy {
  isAllowed = false;
  subscription: Subscription;
  @Input() slug = '';
  @Input() tooltipPosition = 'above';
  @Input() message = 'core-lib.action-wrapper.block';
  @Input() skipValidation = false;
  @Select(UsersActionsState.getActions) actions$;

  constructor(private store: Store) {}

  ngOnInit() {
    this.subscription = this.actions$.subscribe((actions) => {
      this.isAllowed = !!actions.find((action) => action.slug === this.slug);
    });
  }

  get userRole() {
    return this.store.selectSnapshot(UsersActionsState.role);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
