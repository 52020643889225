import { Component, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mh-core-lib-otas-integration-status-modal',
  templateUrl: './otas-integration-status-modal.component.html',
  styleUrls: ['./otas-integration-status-modal.component.scss'],
})
export class CoreLibOtasIntegrationStatusModalComponent extends CoreLibModalComponent {
  readonly GOOGLE_ID = 30;
  readonly FACEBOOK_ID = 31;
  readonly OTAS = [this.GOOGLE_ID, this.FACEBOOK_ID];
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

  handleConnect(customer, otaId) {
    this.dialogRef.close({
      customer,
      otaId,
    });
  }

  handleClose() {
    return this.dialogRef.close();
  }

  isOtaPresent(otaId, status) {
    return !!status.find((otaStatus) => otaStatus.ota_id === otaId);
  }

  existError(otaId, status) {
    const otaStatus = status.find((otaStatus) => otaStatus.ota_id === otaId);
    return otaStatus.status === 'red';
  }

  get currentLanguage() {
    return this.data.currentLanguage;
  }

  get otasErrors() {
    return this.data.otasErrors;
  }

  get isMenuContextCorporative() {
    return this.data.menuContext === 'corporative';
  }
}
