import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StoreCurrentUsers } from './users.actions';
import { UserService } from 'app/_services';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface UsersModel {
  currentUsers: any[];
}

@State<UsersModel>({
  name: 'users',
  defaults: {
    currentUsers: [],
  },
})
@Injectable()
export class UsersState {

  @Selector()
  static currentUsers(state: UsersModel) {
    return state.currentUsers;
  }

  constructor(private userService: UserService) {}

  @Action(StoreCurrentUsers)
  storeCurrentUsers(context: StateContext<UsersModel>, action: StoreCurrentUsers) {
    return this.userService.getByCustomerId(action.currentCustomerId).pipe(
      tap((usersResult: any[]) => {
        context.patchState({
          currentUsers: usersResult,
        });
      }),
    );
  }
}
