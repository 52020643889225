import { Injectable, OnDestroy } from '@angular/core';
import { User, NavbarNode } from '../shared/model/index';
import { Hotel } from '../shared/model/hotel';
import { FidelityProperty, Menus, Widgets } from 'app/shared/model/fidelity-property.model';
import { environment } from 'src/environments/environment';
import { NavbarService } from './navbar.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Select, Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { MenusState } from 'app/states/menus/menus.state';
import { MENU_IDS, MenuItem } from 'app/states/menus/menus.model';
import { AddDataMenu, AddDataSidebar } from 'app/states/menus/menus.actions';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { StoreUsersActions } from 'app/states/users-actions/users-actions.actions';

@Injectable({
  providedIn: 'root',
})
export class ProductsService implements OnDestroy {
  @Select(MenusState.currentMenuSidebar) menuState$: Observable<any>;
  navItems: NavbarNode[] = [];
  permittedAccess: string[] = [];
  dataProducts: any[];
  dataManager: any[];
  dataConfigs: any[];
  allProductAccess = [];
  mh_products = ['interchain', 'followup', 'online', 'onsite', 'collect', 'prestay', 'cases', 'semantic', 'voices'];
  productAccess: any[] = [];
  flagAccess: any[] = [];
  currentUser: User;
  currentHotel: Hotel;
  whiteCards: string[] = [];
  coporativeMenu = [];
  notProduct = [
    // /\/gestor-usuarios/,
    // /\/gestor-usuarios-global/,
    /\/usuarios\/gestor-usuarios/,
    /\/usuarios\/registro-ingresos/,
    /\/recuperar-contrasena/,
    /\/perfil/,
    /\/customer\/configuracion\/hotel\/edit/,
    /\/customer\/configuracion\/hotel\/new/,
    /\/logs/,
    /\/followup\/configuracion\/encuesta\/producto\/1\/editar/,
    /\/followup\/configuracion\/encuesta\/producto\/1\/index/,
    /\/onsite\/configuracion\/encuesta\/producto\/2\/editar/,
    /\/onsite\/configuracion\/encuesta\/producto\/2\/index/,
    /\/secure\/file\/download\/\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    /\/online\/integraciones\/facebook/,
    /\/online\/integraciones\/google/,
    /\/online\/integraciones\/booking/,
    /\/online\/integraciones\/tripadvisor/,
    /\/cases\/redirect/,
  ];
  fidelityConfigurations: FidelityProperty;
  adminFlagsDepth1 = [
    'admin_alert',
    'admin_find_not_clients',
    'admin_followup',
    'admin_onsite',
    'admin_online',
    'admin_config',
    'admin_prestay',
    'admin_desk',
    'admin_collect',
    'admin_voices',
    'admin_smart_replies',
  ];
  // roles for editing stuff like surveys, hotel, etc.
  superAdminRoles = ['full-admin', 'customer-success', 'payments'];
  permittedIds: number[] = [8, 1428, 11173, 16574];
  productsWithMenu;
  dataNavbar: MenuItem[];
  userMenu: MenuItem[];
  userMenuAccess = [];

  navbarStatus: Subscription;

  constructor(
    private navbarService: NavbarService,
    private http: HttpClient,
    private store: Store,
    private usersManagementService: UsersManagmentService,
  ) {
    this.handleNavbarData();
  }

  ngOnDestroy() {
    this.navbarStatus.unsubscribe();
  }

  handleNavbarData() {
    this.navbarStatus = this.navbarService.isStatusChanged().subscribe(async (status) => {
      switch (status) {
        case 0:
          this.loadSidebarMenu();
          break;
        case 1:
          this.dataNavbar = this.store.selectSnapshot(MenusState.currentMenuSidebar).configs;
          break;
        case 2:
          this.dataNavbar = this.store.selectSnapshot(MenusState.currentMenuSidebar).managers;
          break;
        case 3:
          this.dataNavbar = this.coporativeMenu;
          break;
      }
    });

    this.navbarStatus.add(
      this.menuState$.pipe(take(2)).subscribe((state) => {
        this.dataNavbar = state.sidebar;
      }),
    );
  }

  hasNotificationsPermission() {
    return this.permittedIds.includes(this.currentUserId);
  }

  async loadProducts(currentUser: User, currentHotel: Hotel, fidelityProperty: FidelityProperty, isChain = false) {
    this.fidelityConfigurations = fidelityProperty;
    this.currentHotel = currentHotel;
    this.currentUser = currentUser;

    this.store.dispatch(new StoreUsersActions());
    const menuState = await this.store.dispatch(new AddDataMenu(this.currentHotel.id)).toPromise();
    this.dataNavbar = menuState.menus.sidebar;
    this.userMenu = menuState.menus.usermenu;

    this.productsWithMenu = Object.keys(this.fidelityConfigurations.products).reduce((acum, product) => {
      return {
        ...acum,
        [product]:
          product === 'interchain'
            ? isChain && Object.keys(this.fidelityConfigurations.products[product].menu).length > 0
            : Object.keys(this.fidelityConfigurations.products[product].menu).length > 0,
      };
    }, {});

    this.initProducts();
  }

  initProducts() {
    const nodes = this.createNavbarNode(this.dataNavbar);
    return nodes;
  }

  getNavbarItems(): NavbarNode[] {
    return this.initProducts();
  }

  createNavbarNode(items, depth = 0, product = 'none') {
    const nodes: NavbarNode[] = [];
    if (parseInt(this.currentHotel.id) !== 12 && items) {
      for (const item of items) {
        if (item?.slug === 'status_integrations') continue;
        const productType = depth === 0 ? item.slug : product;
        const node = new NavbarNode(
          item.link,
          item.title,
          item.icon,
          item.mat_icon,
          item?.slug,
          depth,
          productType,
          (item.tag  ?? '' as string).toLowerCase() === 'new',
        );
        node.beta = item.beta || false;
        node.superAdmin = item.superAdmin || false;
        node.mobile = item?.mobile || false;

        if (item.childrens && item.childrens.length > 0) {
          const subItems =
            this.currentHotel?.property_type?.description === 'Restaurant'
              ? item.childrens.filter((sub) => sub.title !== 'pricing')
              : item.childrens;
          node.children = this.createNavbarNode(subItems, depth + 1, productType);
        }

        this.navItems.push(node);
        nodes.push(node);
        this.permittedAccess.push(node.id);
      }
    }
    return nodes;
  }

  showInterChain(currentUser: User, currentHotel: Hotel) {
    return currentUser.hasInterChain();
  }

  setFlagAccess(currentUser: User, currentHotel: Hotel) {
    const hotel = currentHotel;
    const accessIds = hotel.accessIds || [];
    const access = currentUser.access.filter((x) => accessIds.includes(x.id));
    return access.map((x) => x.description);
  }

  hasInterChain() {
    const user = this.isSuperAdmin && this.currentUser.fakeUser ? this.currentUser.fakeUser : this.currentUser;
    return (
      user.customers.length > 1 &&
      (this.hasFollowup || this.hasOnline || this.hasOnsite || this.hasCollect || this.hasPrestay)
    );
  }

  getProductItemByLink(link) {
    const node = [...this.navItems, ...this.userMenu].find((x) => x.id === link || x.link === link);
    return node;
  }

  canAccessProduct(flag: any) {
    return this.productAccess.includes(flag);
  }

  hasUrlAccess(url: string) {
    if (url.includes('prestay')) return this.currentHotel.prestay;
    if (this.permittedAccess.includes(url) || this.userMenuAccess.includes(url)) return true;

    const whiteUrl = url.substring(0, url.lastIndexOf('/')) + '/*';
    if (this.whiteCards.includes(whiteUrl)) return true;

    return false;
  }

  hasNotProduct(url) {
    return this.notProduct.some((regex) => regex.test(url));
  }

  redirecrPriority(hotel) {
    const priority = this.getProductsPriority(hotel);
    return this.permittedAccess.includes(priority) ? priority : '/';
  }

  getProductsPriority(hotel) {
    if (hotel.followup && this.productsWithMenu.followup) return '/followup';
    if (hotel.online && this.productsWithMenu.online) return '/online';
    if (hotel.onsite && this.productsWithMenu.onsite) return '/onsite';
    if (hotel.prestay && this.productsWithMenu.prestay) return '/prestay';
    return '/';
  }

  getProductsInLocalFormat(res) {
    const products_json = new FidelityProperty();
    let menus = new Menus();
    let widgets = new Widgets();

    Object.keys(res).forEach((product) => {
      products_json.products[res[product].slug] = {};
      menus = new Menus();
      Object.keys(res[product].childs).forEach((menu) => {
        menus.menu[res[product].childs[menu].slug] = {};
        widgets = new Widgets();
        Object.keys(res[product].childs[menu].childs).forEach((widget) => {
          widgets.widgets.push(res[product].childs[menu].childs[widget].slug);
        });
        menus.menu[res[product].childs[menu].slug] = widgets;
      });
      products_json.products[res[product].slug] = menus;
    });

    return products_json;
  }

  isProductionMode() {
    return `${environment.envName}` === 'production';
  }

  getMenuConfig(customerId, menuId): Observable<MenuItem[]> {
    const url = `${environment.customerPath}customers/${customerId}/menus/${menuId}`;
    return this.http.get<MenuItem[]>(url).pipe(
      map((menu: any[]) => {
        if (menuId === 1) {
          const corporativeNode = menu.find((item) => item.slug === 'corporative');
          if (corporativeNode) {
            this.coporativeMenu = [structuredClone(corporativeNode)];
            corporativeNode.childrens = [];
          }
        }
        return menu;
      }),
    );
  }

  findLeafItem(menu): string | null {
    for (const item of menu) {
      if (item.childrens.length === 0) {
        return item.link;
      }

      const childResult = this.findLeafItem(item.childrens);
      if (childResult !== null) {
        return childResult;
      }
    }
    return null;
  }

  async loadSidebarMenu(status = 0) {
    this.dataNavbar = null;
    const { menu, menuId } = this.getMenuAndId(status || 0);
    const sidebarMenu = this.store.selectSnapshot(MenusState.currentMenuSidebar)[menu];
    if (sidebarMenu?.length > 0) {
      this.dataNavbar = sidebarMenu;
    } else {
      if (this.currentHotel?.id) {
        const dataNav = await this.getMenuConfig(this.currentHotel.id, menuId).toPromise();
        this.store.dispatch(new AddDataSidebar(dataNav));
        this.dataNavbar = dataNav;
      }
    }
  }

  getMenuAndId(status) {
    let menu;
    let menuId;
    switch (status) {
      case 0:
      default:
        menu = 'sidebar';
        menuId = MENU_IDS.SIDEBAR;
        break;
      case 1:
        menu = 'configs';
        menuId = MENU_IDS.CONFIGS;
        break;
      case 2:
        menu = 'managers';
        menuId = MENU_IDS.MANAGERS;
        break;
    }
    return { menu, menuId };
  }

  get currentUserId() {
    return this.currentUser.id;
  }
  get isSuperAdmin() {
    return this.currentUser.isSuperAdmin();
  }
  get hasOnline() {
    return this.currentHotel.online;
  }
  get hasFollowup() {
    return this.currentHotel.followup;
  }
  get hasPrestay() {
    return this.currentHotel.prestay;
  }
  get hasOnsite() {
    return this.currentHotel.onsite;
  }
  get hasCollect() {
    return this.currentHotel.collect;
  }
  get hasDesk() {
    return this.currentHotel.cases;
  }
  get hasVoices() {
    return this.currentHotel.voices;
  }
  get hasSmartReplies() {
    return this.currentHotel.smart_replies;
  }
}
