import { Component, OnInit, Inject, ElementRef, Renderer2 } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';

@Component({
  selector: 'mh-core-lib-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class CoreLibNotificationModalComponent extends CoreLibModalComponent implements OnInit {

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    const parent = this.elRef.nativeElement.parentNode;
    this.renderer.addClass(parent.parentElement, 'max-index-notification-wrapper');
  }

  get type() {
    return this.data.type;
  }

  get user() {
    return this.data.user;
  }

  get distance() {
    return this.data.distance;
  }

}
