import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  throwError as ObservableThrowError,
  Subject,
  forkJoin
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from "src/environments/environment";
import { CheckSetup, CheckSetupRes } from 'app/shared/model/check-setup.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  questionOrderInverted = new Subject();

  constructor(private http:HttpClient) { }

  getTypes() {
    const url = `${environment.surveysPath}types`;
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  getBase(customerId) {
    const url = `${environment.surveysPath}base`;
    const params = new HttpParams()
      .set('customer_id', customerId)
    return this.http.get(url, {params})
      .pipe(
        map(response => response)
      );
  }

  getCustomersSurveys(customers_ids, survey_type_id: any, product_id: number) {
    const url = `${environment.surveysPath}customers/surveys`;
    const params = new HttpParams()
      .set("customers_ids", `${customers_ids}`)
      .set("product_id", `${product_id}`);

    if (survey_type_id)
      params.set("survey_type_id", `${survey_type_id}`)

    return this.http.get(url, {params})
      .pipe(
        map(response => response)
      );
  }

  getCustomerSurveys(customer_id, survey_type_id: number, product_id: number) {
    const url = `${environment.surveysPath}${customer_id}/surveys`;
    const params = new HttpParams()
      .set("survey_type_id", `${survey_type_id}`)
      .set("product_id", `${product_id}`);

    return this.http.get(url, {params})
      .pipe(
        map(response => response)
      );
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: No se pudo completar la petición';
    return ObservableThrowError(errorMsg);
  }

  getSetupByCustomerId(customerId: string, productId: number) {
    const url = `${environment.followupPath}customers/${customerId}/check/setup?product_id=${productId}`;
    return this.http.get<CheckSetupRes>(url)
      .pipe(
        map(resp => CheckSetup.createFromResponse(resp)),
        catchError(error => this._handleError(error))
        )
      .toPromise()
  }

  createSurvey(customer_id, survey) {
    const url = `${environment.surveysPath}customers/${customer_id}/surveys`;
    return this.http.post(url, survey);
  }

  cloneSurvey(customerId: number, surveyId: number) {
    const url = `${environment.surveysPath}customers/${customerId}/clone/surveys/${surveyId}`
    return this.http.post(url, {});
  }

  getFullSurvey(survey_id: number) {
    return forkJoin({
      general: this.getGeneralInfo(survey_id),
      conditional_questions: this.getConditionalQuestions(survey_id),
      sections: this.getSections(survey_id),
    }).pipe(map(({general, ...rest}) => ({...general, ...rest})))
  }

  getGeneralInfo(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/general`;
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  getSetupStatus(id: number): Observable<any> {
    const url = `${environment.surveysPath}${id}/check/setup`;
    return this.http.get(url);
  }

  editGeneralInfo(survey_id: number, survey) {
    const url = `${environment.surveysPath}${survey_id}/general`;
    return this.http.put(url, survey)
      .pipe(
        map(response => response)
      );
  }

  deleteSurvey(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}`;
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  setNpsQuestion(survey_id: number, question) {
    const url = `${environment.surveysPath}${survey_id}/nps`;
    return this.http.post(url, question)
      .pipe(
        map(response => response)
      );
  }

  getSections(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections`;
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  createSection(survey_id: number, section) {
    const url = `${environment.surveysPath}${survey_id}/sections`;
    return this.http.post(url, section)
      .pipe(
        map(response => response)
      );
  }

  editSection(survey_id: number, section_id: number, section) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}`;
    return this.http.put(url, section)
      .pipe(
        map(response => response)
      );
  }

  deleteSection(survey_id: number, section_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}`;
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  createQuestion(survey_id: number, section_id: number, question) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions`;
    return this.http.post(url, question)
      .pipe(
        map(response => response)
      );
  }

  editQuestion(survey_id: number, section_id: number, question, question_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions/${question_id}`;
    return this.http.put(url, question)
      .pipe(
        map(response => response)
      );
  }

  deleteQuestion(survey_id: number, section_id: number, question_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions/${question_id}`;
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  migrateNPS(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/migrate/nps`;
    return this.http.post(url, {})
  }

  getConditionalQuestions(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions`;
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  createConditionalQuestion(survey_id: any, alternative_id: any, question) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions/alternatives/${alternative_id}`;
    return this.http.post(url, question)
      .pipe(
        map(response => response)
      );
  }

  editConditionalQuestion(survey_id, question_id: number, question) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions/${question_id}`;
    return this.http.put(url, question)
      .pipe(
        map(response => response)
      );
  }

  deleteConditionalQuestion(survey_id, question_id: number) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions/${question_id}`;
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  deleteConditionalQuestionAssociated(survey_id, question_id, alternative_id) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions/${question_id}/alternatives/${alternative_id}`;
    return this.http.delete(url);
  }

  createAlternative(survey_id: number, section_id: number, question_id: number, alternative) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions/${question_id}/alternatives`;
    return this.http.post(url, alternative)
      .pipe(
        map(response => response)
      );
  }

  editAlternative(survey_id: number, question_id: number, section_id: number, alternative_id: any, alternative) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions/${question_id}/alternatives/${alternative_id}`;
    return this.http.put(url, alternative)
      .pipe(
        map(response => response)
      );
  }

  deleteAlternative(survey_id: number, question_id: number, section_id: number, alternative_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections/${section_id}/questions/${question_id}/alternatives/${alternative_id}`
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  editTripadvisorToggleStatus(surveyId, $event: boolean) {
    const action = ($event) ? 'enable' : 'disable';
    const url = `${environment.surveysPath}${surveyId}/tripadvisor/widget/${action}`;
    return this.http.patch(url, {});
  }

  // Migracion, borrar despues de que se termine
  getMigrationStatus(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/modifications`
    return this.http.get(url)
  }

  getMigrationErrors(survey_id: number): Observable<any> {
    const url = `${environment.surveysPath}check/migrations`
    return this.http.get(url, {params: {surveys_ids: survey_id.toString()}})
  }

  createMigration(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/migrations`
    return this.http.post(url, {})
  }

  acceptMigration(survey_id: number, migrationId: number) {
    const url = `${environment.surveysPath}${survey_id}/migrations/${migrationId}/approve`;
    return this.http.put(url, {})
  }

  rejectMigration(survey_id: number, migrationId: number) {
    const url = `${environment.surveysPath}${survey_id}/migrations/${migrationId}/reject`
    return this.http.put(url, {})
  }

  // Encuestas pública

  createAnsweredPublicSurvey(survey_id: number, guest_id: number, survey) {
    const url = `${environment.surveysPath}${survey_id}/guests/${guest_id}/answers`;
    return this.http.post(url, survey)
      .pipe(
        map(response => response)
      );
  }

  saveAnswerPublicSurvey(survey_id: number, answered_id: number, answered) {
    const url = `${environment.surveysPath}${survey_id}/answered/${answered_id}`;
    return this.http.post(url, answered)
      .pipe(
        map(response => response)
      );
  }

  changeStatusPublicSurvey(survey_id: number, answered_id: number, status: string, survey) {
    const url = `${environment.surveysPath}${survey_id}/answered/${answered_id}/status/${status}`;
    return this.http.patch(url, survey)
      .pipe(
        map(response => response)
      );
  }

  deletePublicSurvey(survey_id: number, answered_id: number) {
    const url = `${environment.surveysPath}${survey_id}/answered/${answered_id}`;
    return this.http.delete(url)
      .pipe(
        map(response => response)
      );
  }

  getTripAdvisorWidget(survey_id: any, answered_id: number) {
    const url = `${environment.surveysPath}${survey_id}/answered/${answered_id}/tripadvisor/widget`
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  getAnswersPublicSurvey(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/answers`;
    return this.http.get(url)
      .pipe(
        map(response => response)
      );
  }

  startAnswer(surveyId:number, guestId:number, languageId:any){
    const url=`${environment.surveysPath}${surveyId}/guests/${guestId}/answers`;
    const params:HttpParams= new HttpParams()
      .set("language_id",languageId.toString());
    return this.http.post(url,{},{params: params})
  }

  validateToken(surveyToken:string, guestToken:string){

    const url=`${environment.securityPath}surveys/guest/validation`;

    return this.http.post(url,{
      survey_token: surveyToken,
      token: guestToken
    }).pipe(
        map(response=>response)
      )
  }

  // Reprocess Survey
  reprocessSurvey(dates: any, survey_id: string) {
    const url = `${environment.surveysPath}${survey_id}/recalculate/stats`;
    const { startDate, endDate } = dates;
    const params: HttpParams = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
    return this.http.put(url, {}, {params});
  }

  invertScoreOrder(surveyId: number, questionId: number) {
    const url = `${environment.surveysPath}${surveyId}/questions/${questionId}/invert-item-order`;
    return this.http.patch(url, {});
  }

  updateQuestionOrder() {
    this.questionOrderInverted.next(true);
  }

  isQuestionOrderInverted() {
    return this.questionOrderInverted.asObservable();
  }
}
