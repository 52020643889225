<article class="tw-flex tw-h-full">
    <!-- Mobile view -->
    <section id="contact_mobile" class="tw-w-full tw-flex lg:tw-hidden tw-pt-12 tw-relative tw-bg-gray-100 tw-flex-col">
        <div class="tw-w-full tw-flex tw-justify-end tw-absolute tw-top-4 tw-right-6">
            <i class="far fa-times tw-text-3xl tw-text-gray-400" (click)="close()"></i>
        </div>

        <div class="tw-w-full tw-h-fit tw-text-center tw-mb-4" role="group">
            <mh-core-lib-view-guests-selector [options]="tableTypes" [selectedType]="selectedTableType" [disabled]="!manualChannelSelected?.name"
                (selectedOption)="handleSelectedOption($event)"></mh-core-lib-view-guests-selector>
        </div>

        <div class="tw-w-full tw-overflow-y-auto">
            <ng-container
                *ngTemplateOutlet="showLoader ? loaderTemplate : (selectedTableType === 'contact-channel') ? contactChannelTemplate : channelContactList"></ng-container>
        </div>
    </section>

    <!-- Desktop view -->
    <div class="tw-hidden lg:tw-flex tw-w-full ">
        <div 
        [ngClass]="{'tw-pt-28 lg:tw-pt-16': product === 'onsite'}"
        class="tw-absolute tw-pt-5 tw-right-10 !tw-z-20">
            <span
            class="tw-bg-gray-100 tw-rounded-full tw-px-5 tw-py-1 tw-text-base tw-cursor-pointer !tw-z-20"
                (click)="close()">{{ 'commons.close' | translate }}</span>
        </div>

        <ng-container *ngTemplateOutlet="showLoader ? loaderTemplate : content" ></ng-container>

        <ng-template #content >
            <div class="tw-w-full tw-flex tw-flex-row">
                <section class="tw-flex tw-w-full" *ngIf="manualChannelSelected?.name">
                    <ng-container *ngTemplateOutlet="channelContactList"></ng-container>
                    <ng-container *ngTemplateOutlet="contactChannelTemplate"></ng-container>
                </section>
                <section class="tw-w-full" *ngIf="!manualChannelSelected?.name">
                    <ng-container *ngTemplateOutlet="selectContactChanel"></ng-container>
                </section>
            </div>
        </ng-template>
    </div>
</article>

<!-- Templates -->
<!-- Email template -->
<ng-template #contactChannelTemplate>
    <ng-container *ngTemplateOutlet="manualChannelSelected?.name === 'email' ? email : description"></ng-container>


    <ng-template #email>
        <section [ngClass]="{'tw-pt-28 lg:tw-pt-16': product === 'onsite'}"
            class="tw-basis-0 lg:tw-basis-3/5 tw-px-6 xl:tw-px-8 tw-py-5 tw-mx-5 xl:tw-mx-0 tw-my-1 xl:tw-my-0 tw-flex tw-flex-col tw-relative tw-bg-white tw-rounded-xl lg:tw-rounded-none tw-overflow-y-auto">
            <div *ngIf="product === 'onsite'"
                class="tw-bg-yellow-300 tw-py-3 tw-px-8 tw-absolute tw-top-0 tw-left-0 tw-w-full">
                <span class="tw-text-sm  tw-flex tw-mr-12 "><b>{{ 'answer_survey.contact_dialog.attention' | translate
                        }}</b> {{
                    showCheckoutMessage }}</span>
            </div>
            <div *ngIf="disableContactSection"
                class="tw-h-full tw-w-full tw-bg-slate-100 tw-opacity-60 tw-absolute tw-top-0 tw-left-0 tw-z-10"></div>
            <div class="tw-flex tw-border-b tw-border-gray-300 tw-pb-4">
                <p class="tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2">
                    <img [ngClass]="{ 'tw-grayscale': disableContactSection }"
                        src="https://mh-statics.s3.sa-east-1.amazonaws.com/uitls/smart-reply-icon-01.svg"
                        alt="smart-reply-image">{{ 'answer_survey.contact_dialog.smart_reply' | translate }}
                </p>
                <div class="tw-font-normal tw-text-base tw-flex tw-items-center tw-gap-2 ml-2 " *ngIf="!limitError">
                    <p class="tw-italic tw-text-base"> ( {{
                        'answer_survey.contact_dialog.generated' |
                        translate }} )</p>
                </div>
                <div class="tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2 ml-2 " *ngIf="limitError">
                    <p class="tw-text-red-500 tw-italic tw-text-base"> {{ 'smart_replies.action_button.limit_exceeded_error' | translate: { limit: limitError } }}</p>
                </div>
            </div>
            <div class="tw-border-b tw-border-gray-300 tw-py-3">
                <p class="tw-text-gray-400">{{ 'answer_survey.contact_dialog.from' | translate }} {{ customerFrom }}</p>
            </div>
            <div class="tw-border-b tw-border-gray-300 tw-py-3">
                <p class="tw-text-gray-400">{{ 'answer_survey.contact_dialog.to' | translate }} {{ survey?.guest?.email
                    }}
                </p>
            </div>
            <div class="tw-flex tw-items-center tw-border-b tw-border-gray-300 tw-py-3">
                <p class="tw-text-gray-400">{{ 'answer_survey.contact_dialog.subject_title' | translate }}</p>
                <mh-core-lib-text-input class="tw-w-full"
                    [className]="'!tw-text-black !tw-py-0 !tw-pl-1 !tw-border-none !tw-text-base !tw-font-[Rubik]'"
                    [formGroup]="contactSurveyForm" [keyControl]="'subject'"></mh-core-lib-text-input>
            </div>
            <div class="tw-pt-5 tw-mb-auto tw-flex-grow tw-relative">
                <mh-core-lib-textarea-input [formGroup]="contactSurveyForm" [keyControl]="'body'"
                    customContainerClass="tw-h-full"
                    [inputClass]="'!tw-border-none tw-resize-none tw-p-0 tw-h-60 xl:tw-h-full tw-text-base !tw-font-[Rubik]'"
                    (textareaReady)="focosOnTextarea($event)"></mh-core-lib-textarea-input>
            </div>
            <div class="tw-flex tw-justify-end tw-mb-5">
                <i class="far fa-copy tw-text-3xl tw-text-gray-500 tw-cursor-pointer" (click)="copySmartReply()"
                    [mhTooltip]="'answer_survey.contact_dialog.copy_reply' | translate"></i>
            </div>
            <div class="tw-flex tw-justify-center md:tw-justify-between tw-items-center tw-flex-wrap">
                <div class="tw-mb-2 md:tw-mb-0">
                    <span class="tw-text-sm tw-italic tw-text-gray-400">{{ 'answer_survey.contact_dialog.can_edit' |
                        translate
                        }}</span>
                </div>
                <div class="tw-flex">
                    <mh-core-lib-button [isSecondary]="true"
                        [isDisabled]="disableContactSection || !this.survey?.guest?.phone"
                        [text]="'answer_survey.dialog.whatsapp_button' | translate" [width]="'tw-w-fit'" [height]="38"
                        [customClass]="'!tw-shadow-none tw-font-normal'"
                        [mhTooltip]="!this.survey?.guest?.phone ? ('answer_survey.contact_dialog.no_phone' | translate) : null"
                        position="above" (clicked)="showConfirmDialog('whatsapp')">
                    </mh-core-lib-button>
                    <mh-core-lib-actions-wrapper [slug]="contactEmailSlug">
                        <mh-core-lib-button class="tw-ml-3" [isSecondary]="true"
                            [isDisabled]="disableContactSection || !isEmailValid"
                            [mhTooltip]="!isEmailValid ? ('answer_survey.dialog.invalid_email' | translate) : null"
                            position="above" [text]="'answer_survey.dialog.email_button' | translate"
                            [width]="'tw-w-fit'" [height]="38" [customClass]="'!tw-shadow-none tw-font-normal'"
                            (clicked)="showConfirmDialog('email')">
                        </mh-core-lib-button>
                    </mh-core-lib-actions-wrapper>
                </div>
            </div>
        </section>
    </ng-template>

    <ng-template #description>
        <section [ngClass]="{'tw-pt-28 lg:tw-pt-16': product === 'onsite'}"
            class="tw-basis-0 lg:tw-basis-3/5 tw-px-6 xl:tw-px-8 tw-py-5 tw-mx-5 xl:tw-mx-0 tw-my-1 xl:tw-my-0 tw-flex tw-flex-col tw-relative tw-bg-white tw-rounded-xl lg:tw-rounded-none tw-overflow-y-auto md:tw-justify-end">
            <div class="tw-mb-4 lg:tw-mb-auto">
                <p  *ngIf="manualChannelSelected?.name != 'whatsapp'" class="tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2 tw-mb-6">
                    {{ 'answer_survey.contact_dialog.description' | translate }}
                </p>

                <div *ngIf="manualChannelSelected?.name === 'whatsapp'" class="tw-flex tw-border-b tw-border-gray-300 tw-pb-4">
                    <p class="tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2">
                        <img [ngClass]="{ 'tw-grayscale': disableContactSection }"
                            src="https://mh-statics.s3.sa-east-1.amazonaws.com/uitls/smart-reply-icon-01.svg"
                            alt="smart-reply-image">{{ 'answer_survey.contact_dialog.smart_reply' | translate }}
                    </p>
                    <div class="tw-font-normal tw-text-base tw-flex tw-items-center tw-gap-2 ml-2 " *ngIf="!limitError">
                        <p class="tw-italic tw-text-base"> ( {{
                            'generada por myHotel I.A' |
                            translate }} )</p>
                    </div>
                    <div class="tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2 ml-2 " *ngIf="limitError">
                        <p class="tw-text-red-500 tw-italic tw-text-base"> {{ 'smart_replies.action_button.limit_exceeded_error' | translate: { limit: limitError } }}</p>
                    </div>
                </div>

                <mh-core-lib-textarea-input [formGroup]="contactSurveyForm" [keyControl]="'description'"
                    [inputClass]="'!tw-border-gray-300 tw-border-2'"
                    [placeholder]="'cases.detail_modal.write_a_description' | translate"
                    [rows]="12"></mh-core-lib-textarea-input>
            </div>
            <div class="tw-flex tw-w-full tw-justify-end">
                <div class="tw-flex tw-text-center lg:tw-text-right tw-w-fit tw-justify-end tw-space-x-3">
                    <mh-core-lib-button [isSecondary]="true" *ngIf="manualChannelSelected?.name === 'whatsapp'"
                        [isDisabled]="!this.survey?.guest?.phone"
                        [text]="'answer_survey.dialog.whatsapp_button' | translate" [width]="'tw-w-auto'" [height]="38"
                        [customClass]="'!tw-shadow-none tw-font-normal'"
                        [mhTooltip]="!this.survey?.guest?.phone ? ('answer_survey.contact_dialog.no_phone' | translate) : null"
                        position="above" (clicked)="showConfirmDialog('whatsapp')">
                    </mh-core-lib-button>

                    <mh-core-lib-actions-wrapper [slug]="contactEmailSlug">
                        <mh-core-lib-button [isSecondary]="true"
                            [text]="'answer_survey.contact_dialog.register_contact' | translate" [width]="'tw-w-auto'"
                            [height]="38" 
                            [mhTooltip]="this.contactSurveyForm.get('description')?.value?.length < 3 ? ('answer_survey.contact_dialog.min_chars' | translate) : null"
                            [isDisabled]="this.contactSurveyForm.get('description')?.value?.length < 3"
                            [customClass]="'!tw-shadow-none tw-font-normal'" (clicked)="registerContact('manual')">
                        </mh-core-lib-button>
                    </mh-core-lib-actions-wrapper>
                </div>
            </div>
        </section>
    </ng-template>

</ng-template>

<!-- channelContactList template -->
<ng-template #channelContactList>
    <section
        class="lg:tw-w-1.5 tw-basis-0 lg:tw-basis-2/5 tw-bg-white lg:tw-bg-gray-100 tw-px-6 lg:tw-px-10 tw-py-5 tw-mx-5 lg:tw-mx-0 tw-my-1 lg:tw-my-0 tw-flex tw-flex-col tw-rounded-xl lg:tw-rounded-none tw-overflow-y-auto">
        <div>
            <p class="tw-text-base tw-font-medium">{{ 'answer_survey.contact_dialog.contact_channels' | translate }}</p>
        </div>

        <div *ngIf="showNotSelectedChannelError" >
            <p class="tw-text-sm tw-font-light tw-text-red-500">{{ 'answer_survey.errors.not_selected_channel' | translate }}</p>
        </div>

        <div class="tw-mt-8 tw-mb-4 lg:tw-mb-auto">
            <mh-core-lib-checkbox-input [options]="manualChannelOptions" [needTranslate]="true" [labelText]="'id'"
                [labelClass]="'tw-capitalize tw-text-base tw-font-normal tw-font-[Rubik]'"
                [inputClass]="'tw-w-6 tw-h-6 !tw-border-gray-400 tw-border-2'" [listClass]="'tw-mb-5'"
                [translatePrefix]="'answer_survey.channels.'" [isSingleSelection]="true"
                (optionClicked)="handleManualOptionClicked($event)">
            </mh-core-lib-checkbox-input>
        </div>
    </section>
</ng-template>

<ng-template #selectContactChanel>
    <section
        class="tw-w-full tw-h-full tw-basis-0 lg:tw-basis-2/5 tw-bg-white tw-px-6 lg:tw-px-10 tw-py-5 tw-mx-5 lg:tw-mx-0 tw-my-1 lg:tw-my-0 tw-flex tw-items-center tw-justify-center tw-rounded-xl lg:tw-rounded-none tw-overflow-y-auto">
        <div class="tw-container tw-mx-auto tw-p-4 tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
            <h1 class="tw-text-5xl tw-font-normal tw-mb-16 tw-text-center tw-font-[Rubik] tw-max-w-2xl">{{ 'answer_survey.contact_dialog.choose_channel' |
                translate }}</h1>

            <div class="tw-flex tw-flex-wrap tw-justify-center tw-space-x-8">
                <div *ngFor="let item of iconItems"
                    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-8 tw-space-y-4 tw-border tw-border-sky-500 tw-p-20 tw-rounded-2xl tw-w-40 tw-h-40"
                    [ngClass]="{'tw-cursor-pointer': !item.disabled, 'tw-cursor-not-allowed tw-opacity-50': item.disabled}"
                    (click)="handleIconClicked(item)"
                    [mhTooltip]="item.disabled ? ('answer_survey.contact_dialog.no_contact_channel' | translate) : null">
                    <div class="tw-w-full tw-flex tw-justify-center" [ngClass]="{'tw-mr-5': optionsAvailableSmartReply.includes(item?.id)}" >
                        <p class="tw-flex tw-items-center tw-text-center">
                          <img
                            *ngIf="optionsAvailableSmartReply.includes(item?.id)"
                            class="tw-mr-1"
                            src="https://mh-statics.s3.sa-east-1.amazonaws.com/uitls/smart-reply-icon-01.svg"
                            alt="smart-reply-image">
                          {{ 'answer_survey.channels.' + item.id | translate }}
                        </p>
                      </div>
                    <div class="tw-flex-grow"></div>
                    <div class="tw-text-5xl"
                        [ngClass]="{'tw-text-sky-500': !item.disabled, 'tw-text-gray-400': item.disabled}">
                        <i [class]="item.icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>

<ng-template #loaderTemplate>
    <article 
      id="loaderTemplate"
      *ngIf="showLoader"
      class="tw-w-full tw-h-full tw-bg-white tw-absolute tw-z-10 tw-left-0"
    >
    <section class="tw-px-6 tw-py-24 tw-flex tw-flex-col tw-items-center tw-gap-24 tw-h-full">
      <div>
        <p class="tw-text-center tw-text-2xl tw-font-semibold">{{ 'answer_survey.loader_msg' | translate }}</p>
      </div>
      <div>
        <img class="tw-w-40" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/smart-replies-bot.gif" alt="Smart reply loader gif">
      </div>
    </section>
    </article>
  </ng-template>