<mh-alerts-wrapper>
	<mh-core-lib-content>
		<ng-container head>
			<mh-core-lib-header-content [title]="'navbar.product.default.' + activeRoute | translate" (click)="headerAreaClicked()">
				<section class="tw-flex tw-justify-center tw-items-center tw-flex-col xs:tw-flex-row md:tw-justify-between">
					<article class="tw-flex tw-space-x-4">
						<article>
							<mh-core-lib-datepicker
									[currentDates]="currentDates"
									[currentFilter]="currentFilterDate"
									(datepickerUpdated)="handleDatepickerUpdated($event)">
							</mh-core-lib-datepicker>
						</article>
						<article *ngIf="['otas', 'reviews'].includes(activeRoute)">
							<mh-competitors-selector id="selectorCompetitor"></mh-competitors-selector>
						</article>
			  <article *ngIf="activeRoute === 'reviews'">
				<mh-core-lib-new-reviews-filter id="filtersReviews"></mh-core-lib-new-reviews-filter>
			  </article>
					</article>
			<article class="tw-basis-1/2" *ngIf="activeRoute === 'summary'">
			  <div class="tw-mb-3 tw-w-full tw-bg-[#ffd748] tw-rounded-md tw-p-1.5 tw-text-center" [innerHTML]="'iro.commons.summary-label' | translate: {video_link: video, url: surveyUrl} | sanitizeHtml">
			  </div>

			</article>
					<article class="tw-flex tw-mt-3 xs:tw-mt-0">
            <mh-core-lib-actions-wrapper
                [slug]="reportSlug"
              >
              <mh-core-lib-report-button
                  class="tw-ml-2 tw-hidden lg:tw-flex"
                  [type]="activeReport"
                  [activeEmail]="false"
                  [chain]="chain"
                  >
              </mh-core-lib-report-button>
            </mh-core-lib-actions-wrapper>
					</article>
				</section>
			</mh-core-lib-header-content>
		</ng-container>

		<ng-container main-content>
			<router-outlet></router-outlet>
		</ng-container>
	</mh-core-lib-content>
</mh-alerts-wrapper>
